<template>
  <div>
    <v-container>
      <v-toolbar flat dense>
        <v-spacer />
        <c-date-picker 
          class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.fromDate')"
          dense outlined  hide-details="auto"
          v-model="plannerCodeRange.fromDate">
        </c-date-picker>
        <c-date-picker
          class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.toDate')"
          dense
          outlined
          :max="monthRestriction"
          v-model="plannerCodeRange.toDate"
          hide-details="auto"
        ></c-date-picker>

        <v-autocomplete
          class="mr-2"
          outlined
          dense
          :label="$t(`plannerCodeRange.Select`)"
          :items="gatherItems"
          :item-text="$i18n.locale == 'ja' ? 'jp' : 'en'"
          item-value="id"
          hide-details="auto"
          style="max-width: 20%"
          v-model="gatherby"
          @change="sortedRankingData.length > 0 ? loadData() : ''"
        />
        <v-btn
          @click="loadData()"
          class="mr-2"
          xxl
          :disabled="loading || !gatherby"
          Length="5"
          color="#0D47A1"
          :style="{ color: 'white' }"
        >
          {{$t(`generate.generate`)}}
        </v-btn>
    
        <v-btn
          v-if="header.header.length > 0"
          @click="dialogCategory=true"
          class="mr-2"
          xxl
          :disabled="loading"
          Length="5"
          color="#0D47A1"
          :style="{ color: 'white' }"
        >
          {{$t(`plannerCodeRange.Select`)}}
        </v-btn>
          
        <v-btn
          v-if="header.header.length > 0"
          class="mr-2"
          xxl
          color="#0D47A1"
          :disabled="loading"
          :style="{ color: 'white' }"
          @click="ExportFile(sortedRankingData, categorySelected, POTSelected, header, gatherby)"
        >
          {{$t(`generate.generateXLSX`)}}
        </v-btn>

        <v-spacer/>
      </v-toolbar>
    </v-container>
    <v-dialog v-model="dialogCategory" width="400px" persistent   transition="fab-transition">
      <v-card height="auto">
        <v-toolbar dense color="primary" dark>
          <span>{{ '項目' }}</span>
          <v-spacer/>
          <v-icon @click="dialogCategory = false" color="red" medium>mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-radio-group v-model="categorySelected" dense  @change="sortHeaders()">
            <v-radio
              v-for="(item,i) in items"
              :key="i"
              :label="`${item.title}`"
              :value="item"
              @click="dialogCategory = false"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-dialog> 
      <div class="pl-8 pr-8" v-if="sortedRankingData.length > 0">
        <div style="display:flex; flex-direction:row">
          <v-radio-group v-model="POTSelected" dense row @change="setPeriodOfTime(POTSelected)">
            <v-radio
              v-for="(item, i) in PeriodofTime"
              :key="i"
              :label="$t(`timePeriod.${item}`)"
              :value="item"
            ></v-radio>
          </v-radio-group>
        
          <v-radio-group v-model="buildingType" dense row @change="setBuildingCategory(buildingType)" class="ml-15">
            <v-radio
              v-for="(item, i) in typesOfBuilding"
              :key="i"
              :label="$t(`buildingCategory.${item}`)"
              :value="item"
            ></v-radio>
          </v-radio-group>
        </div>
        <!-- {{ header }} -->
        <v-card flat v-if="sortedRankingData.length > 0">
        <v-simple-table id="tableRef" dense fixed-header :height="plannerData.tableHeight" style="border: solid black 1px">
          <thead class="sticky-thead" style="top: 0px;">
            <tr>
              <!-- <th style="height: 3rem; font-size: 1.5rem" :colspan="POTSelected === 'Monthly'? 18: (POTSelected === 'Quarterly' ? 9 : 7)">{{ categorySelected.title }} -->
              <th style="height: 3rem; font-size: 1.5rem" :colspan="POTSelected === 'Monthly'? 18: (POTSelected === 'HalfYear' ? 7 : 5)">{{ categorySelected.title }}
              </th>
            </tr>
            <tr>         
              <th rowSpan="2"><span>{{$t(`header.Rank`)}}</span></th>
              <th rowSpan="2" v-if="GatheredSelected =='Planners'"><span>{{$t(`header.SalesOffice`)}}</span></th>
              <th rowSpan="2" v-if="GatheredSelected =='Planners'"><span>{{$t(`header.ExhibitionHall`)}}</span></th>
              <th rowSpan="2"><span>{{ GatheredSelected == 'Planners' ? $t(`header.Planner`) : $t(`header.SalesOffice`)}}</span></th>
              <!-- <th rowSpan="2">項目</th> -->
              <template v-if="POTSelected =='Monthly'" >
                <th v-for="(item,i) in header.header" :key="i" :colspan="item.Month.length">{{ item.Year }}</th>
              </template>
              <!-- <template v-else> -->
              <template v-else-if="POTSelected =='Quarterly' || POTSelected =='HalfYear'">
                  <th v-for="(item, i) in header.header" :key="i">
                    <v-tooltip top color="#4682B4">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.Year }}</span>
                      </template>
                      <span v-if="POTSelected == 'Quarterly'">{{ `${formatDate(quarterly[i].start)}` ? `${formatDate(quarterly[i].start)} ~ ${formatDate(quarterly[i].end)}` : 'Out of Date Range'}}</span>
                      <span v-else-if="POTSelected == 'HalfYear'">{{`${formatDate(halfyear[i].start)}` ? `${formatDate(halfyear[i].start)} ~ ${formatDate(halfyear[i].end)}` : 'Out of Date Range'}}</span>
                    </v-tooltip>
                  </th>
              </template>
              <th :rowSpan="POTSelected =='Monthly' ? 2 :0">{{$t(`content.total`)}}</th>

            </tr>
            <tr v-if="POTSelected =='Monthly'"> 
                <th v-for="(item,i) in header.month" :key="i">{{ `${item}月` }} </th>
            </tr>
          </thead>
          <tbody ref="tableBody">
            <tr v-for="(item,i) in sortedRankingData" :key="i" :style="{ 'background-color': GatheredSelected !='Planners' && i == 0 ? '#C0C0C0' : '#FFFFFF' }">
                <td v-if="GatheredSelected =='Planners'">{{ GatheredSelected =='Planners' ? i+1 : i==0 ? '' : i }}</td>
                <td v-else :colspan="i==0 ? 2 : 1">{{ i==0 ? item.SalesOfficeName : i }}</td>
                <td v-if="GatheredSelected =='Planners'">{{ item.SalesOfficeName }}</td>
                <td v-if="GatheredSelected =='Planners'">{{ item.BranchName }}</td>
                <td v-if="GatheredSelected =='Planners'">{{ item.EmployeeName}}</td>
                <td v-else-if="i != 0">{{ item.SalesOfficeName  }}</td>
                <template v-if="POTSelected !='WholeTerm'">
                  <td v-for="(data,index) in sortedData(item)" :key="index">
                    <span class="ma-n2">{{ data[`${categorySelected.id}`] ? data[`${categorySelected.id}`] == 'NaN' ? '0.0' :  data[`${categorySelected.id}`] : '―' }}</span>
                  </td>
                </template>
                <td> {{ item[`${categorySelected.id}`] == 'NaN' ? '0.0' :  item[`${categorySelected.id}`] }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>  
    <div v-if="!loading && sortedRankingData.length == 0">
      <Logo />
    </div>
    <div v-else >
      <Loader :loading="loading" />
    </div>
  </div>
</template>

<script>
import CDatePicker from "../components/CDatePicker.vue";
import moment from 'moment';
import Swal from "sweetalert2";
// import _ from 'lodash';
import axios from 'axios';
import ExcelJS from 'exceljs'
import MERGING from "../assets/class/SalesOfficeRanking/merging"
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import PlannerData from "../assets/class/PlannerData/PlannerData";
import Logo from "../components/Logo.vue";
import Loader from "../components/Loader.vue";
export default {
  // components: { CDatePicker, OngoingReplication },
  components: { CDatePicker, Logo, Loader},
  data() {
    return {
      plannerData: new PlannerData(),
      salesOfficefunction: new SalesOfficeClass(),
      plannersCodefunction: new PlannerCodeClass(),
      plannerCodeRange: {
          fromDate: moment().subtract(365,'days').startOf('day').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          // fromDate: '2023-01-01',
          // toDate: '2023-07-31',
          Planner: {},
      },
      merge: new MERGING(),
      SalesOfficeArr:[],
      SalesLoading:false,
      user:{},
      planners:[],
      plannersLoading:false,
      datePicker: {},
      loading:false,
      SO:'',
      dialog:false,dialogCategory:false,
      firstProcess:[],
      plannerSelected:'ICHIJO',
      thArr:[],CadArr:[],tempFinalData:[],
      header:{
        header:[],
        month:[]
      },
      RankingData:[],
      // RankingData:[{AllBuildingTypes:[], StandardPlan:[], NonStandardPlan:[]}],
      NationalData:[],
      nationalDataExist: false,
      // PeriodofTime:['Monthly','Quarterly','HalfYear'],POTSelected:'Monthly',
      PeriodofTime:['Monthly','HalfYear','WholeTerm'],POTSelected:'Monthly',
      typesOfBuilding:['AllBuildingTypes','StandardPlan','NonStandardPlan'],buildingType:'AllBuildingTypes',
      gatherby:'',
      gatherItems:[{id: 2, en:'SalesOffice',jp:'営業所'},{id: 1, en:'Planners','jp':'設計担当者'}],
      GatheredSelected:null,
      categorySelected : {id:'KakouIrai' ,title:'加工依頼数'},
      items:[
        {id:'KakouIrai' ,title:'加工依頼数'},
        {id:'TH' ,title:'追加変更数'},
        {id:'THAvg' ,title:'1件あたりの追加変更数'},
        {id:'CadAvg' ,title:'CAD_依頼回数平均'},
        {id:'CadExpressAvg' ,title:'即日CAD_依頼数平均'},
        // {id:'CadPhase1Avg' ,title:'設計受託契約前'},
        {id:'CadPdrAvg' ,title:'提案電気図面依頼以降'},
        {id:'CadFinalAvg' ,title:'着手図面依頼以降'},
        {id:'DenkiAvg' ,title:'電気図面_依頼回数平均'},
        {id:'DenkiExpressAvg' ,title:'即日電気_依頼数平均'},
        {id:'ExternalPersAvg' ,title:'カラーパース_依頼回数平均'},
        {id:'House_presentationAvg' ,title:'HP_依頼回数平均'},
        {id:'SI_WAKUAvg' ,title:'構造事前検討（枠）_依頼回数平均'},
        {id:'SI_JIKUAvg' ,title:'構造事前検討（軸）_依頼回数平均'},
        {id:'ALVSAvg' ,title:'ALVS_依頼回数平均'},
        {id:'DecidedLandDaysAvg' ,title:'土地決定～加工依頼_日数平均'},
        {id:'StopCheckAvg' ,title:'STOP～加工依頼_日数平均'},
        {id:'FinalPlanAvg' ,title:'着手準備~加工依頼_日数平均'},
        {id:'JoutouDaysAvg' ,title:'加工依頼～上棟_日数平均'},
      ],
      tempData:{
        TH: 0, KakouIrai: 0, THAvg: 0,  
        ALVS: 0, ALVS_Total: 0, ALVSAvg: 0, 
        Denki: 0, Denki_Total: 0, DenkiAvg: 0, 
        DenkiExpress: 0, DenkiExpress_Total: 0, DenkiExpressAvg: 0, 
        ExternalPers: 0, ExternalPers_Total: 0, ExternalPersAvg: 0,
        House_presentation: 0, House_presentation_Total: 0, House_presentationAvg: 0,
        DecidedLandDays: 0, DecidedLandDays_Total: 0, DecidedLandDaysAvg: 0,
        FinalPlan: 0, FinalPlan_Total: 0, FinalPlanAvg: 0,
        StopCheck: 0, StopCheck_Total: 0, StopCheckAvg: 0,
        JoutouDays: 0, JoutouDays_Total: 0, JoutouDaysAvg: 0,
        SI_JIKU: 0, SI_JIKU_Total: 0, SI_JIKUAvg: 0,
        SI_WAKU: 0, SI_WAKU_Total: 0, SI_WAKUAvg: 0,
        Cad: 0, Cad_Total: 0, CadAvg: 0,
        CadExpress: 0, CadExpress_Total: 0, CadExpressAvg: 0,
        CadPhase1: 0, CadPhase1_Total: 0, CadPhase1Avg: 0,
        CadPdr: 0, CadPdr_Total: 0, CadPdrAvg: 0,
        CadFinal: 0, CadFinal_Total: 0, CadFinalAvg: 0
      },
      CadDetails: [],
      QuarterlyDates: [],
      HalfyearDates: [],
      ranges: [],
      monthly: [],
      quarterly: [],
      halfyear: [],
      sortingInProgress: false,
    }
  },
  methods: {
      // ongoingReplication(){
      //   this.$refs.ongoingReplicationDialog.openDialog();
      // },
      calculateTotal(propertyName, details) {
        return details.reduce((acc, item) => acc + item[propertyName], 0);
      },
      translate(data){
        return this.$t(`timePeriod.${data}`);
      },
      details(){
        this.$refs.detailsDialog.openDialog();
      },
      tofixed2(num){
        let newval = (Math.round((num) * 10) / 10).toFixed(1)
          if(newval == isNaN || newval == 'NaN'){
            return '0.0'
          }else{
            return newval
          }
      },
      FilterData(data){
        let planner=[]
        let test=[]
        data.forEach(x=>{
          if(x.EmployeeCode != null ){
              let index = planner.findIndex(y=> y.EmployeeCode==x.EmployeeCode)
                  if(index==-1){
                      planner.push({EmployeeCode:x.EmployeeCode})
                  }
          }   
        })
        planner.forEach(rec=>{
          let arr=[]
          data.forEach(rec1=>{ 
            if(rec.EmployeeCode == rec1.EmployeeCode){
              arr.push(rec1)
            }
          })
          test.push(arr)
        })
        return(test)
      },
      async loadData(){
        this.loading=true
        this.RankingData = []
        this.tempFinalData = []
        this.GatheredSelected = null
        let data ={
          fromDate  :moment(this.plannerCodeRange.fromDate).format('YYYY/MM/DD 00:00:00'),
          toDate:moment(this.plannerCodeRange.toDate).format('YYYY/MM/DD 23:59:59'),
          SalesOfficeName:this.userInfo.User_Category
        }
// gathering for TH 
       await axios.post(`${this.aws}newthRanking`,data,this.apiKey).then(res=>{
          if(res.data){
            this.thArr = res.data.reduce((total,item)=>{
              let index = total.findIndex(x=> x.EmployeeCode == item.EmployeeCode)
              if(index > - 1 ){
                total[index].TH += item.TH
                total[index].ConstructionCode.push({ConstructionCode:item.ConstructionCode,ConstructionTypeName:item.ConstructionTypeName,SentProcessRequestDate:item.SentProcessRequestDate,TH:item.TH})
                total[index].KakouIrai += item.SentProcessRequestDate ?  1 : 0
                return total;
              }else{
                total.push({
                  SalesOfficeCode:item.SalesOfficeCode,
                  SalesOfficeName:item.SalesOfficeName,
                  EmployeeCode:item.EmployeeCode,
                  EmployeeName:item.employee_name,
                  BranchCode:item.ContractExhibitionCode,
                  BranchName:item.ContractExhibitionName,
                  ConstructionCode:[
                    {ConstructionCode:item.ConstructionCode,ConstructionTypeName:item.ConstructionTypeName,SentProcessRequestDate:item.SentProcessRequestDate,TH:item.TH}
                  ],
                  TH:item.TH,
                  KakouIrai : item.SentProcessRequestDate ?  1 :  0,
                })
                return total;
              }
            },[])
          }else{
            console.log('NO DATA')
          }
        }).catch(err=>{
          if( err.response  && (  err.response.status == 403 || err.response.status == 401)){
              this.logout()
          }else{
            console.log(err,'error')
          }
          this.loading =false
          return false;
        })
// gathering for Cad request   
await this.merge.MergeFnc(this.aws,data,this.apiKey).then(async(res)=>{
          await axios.get(`${this.aws}getSalesOffices`,this.apiKey).then(r=>{
              let newR=[]
              if(this.userInfo.User_Category == 'admin' || this.userInfo.User_Category == 'Sales User'){
                for (let i = 0; i < r.data.length; i++) {
                      const x = r.data[i];
                      if(this.plannerSelected=='GC'){
                        if((!x.SalesOfficeCode && !x.SalesOfficeName) && (
                          x.EmployeeCode[0] =='A'||x.EmployeeCode[0] =='B'||x.EmployeeCode[0] !='D'||
                          x.EmployeeCode[0] =='F'||x.EmployeeCode[0] =='I'||x.EmployeeCode[0] !='J')) {
                          newR.push(x)
                        }
                        else if((x.SalesOfficeCode  && x.SalesOfficeName ) && x.SalesOfficeName.includes('GC') && x.SalesOfficeCode !='K10' ){
                          newR.push(x)
                        }
                        else{
                          continue;
                        }
                      }
                      else if(this.plannerSelected=='ICHIJO'){
                        if( (x.SalesOfficeCode && x.SalesOfficeName )  && !x.SalesOfficeName.includes('GC')){
                          newR.push(x)
                        }
                        else if((!x.SalesOfficeCode && !x.SalesOfficeName) && (
                          x.EmployeeCode[0] !='A'||x.EmployeeCode[0] !='B'||x.EmployeeCode[0] !='D'||
                          x.EmployeeCode[0] !='F'||x.EmployeeCode[0]!='I'||x.EmployeeCode[0] !='J')) {
                          newR.push(x)
                        }else{
                          continue;
                        }
                      }else{
                        continue;
                      }
                  }
              }
              else{
                  for (let i = 0; i < r.data.length; i++) {
                      const x = r.data[i];
                      if(this.userInfo.User_Category == 'GC User'|| this.userInfo.User_Category == 'GC' || this.userInfo.User_Category == 'gc User' || this.userInfo.User_Category == 'gc'){
                        if((!x.SalesOfficeCode && !x.SalesOfficeName) && (
                          x.EmployeeCode[0] =='A'||x.EmployeeCode[0] =='B'||x.EmployeeCode[0] !='D'||
                          x.EmployeeCode[0] =='F'||x.EmployeeCode[0] =='I'||x.EmployeeCode[0] !='J')) {
                          newR.push(x)
                        }
                        else if((x.SalesOfficeCode  && x.SalesOfficeName ) && x.SalesOfficeName.includes('GC') && x.SalesOfficeCode !='K10' ){
                          newR.push(x)
                        }
                        else{
                          continue;
                        }
                      }
                      else if(this.userInfo.User_Category == 'Ichijo User'|| this.userInfo.User_Category == 'ICHIJO' || this.userInfo.User_Category == 'ichijo'  || this.userInfo.User_Category == 'ichijo user'){
                        if( (x.SalesOfficeCode && x.SalesOfficeName )  && !x.SalesOfficeName.includes('GC')){
                          newR.push(x)
                        }
                        else if((!x.SalesOfficeCode && !x.SalesOfficeName) && (
                          x.EmployeeCode[0] !='A'||x.EmployeeCode[0] !='B'||x.EmployeeCode[0] !='D'||
                          x.EmployeeCode[0] !='F'||x.EmployeeCode[0]!='I'||x.EmployeeCode[0] !='J')) {
                          newR.push(x)
                        }else{
                          continue;
                        }
                      }else{
                        continue;
                      }
                  }
              }
          let newMerge=this.FilterData(res.data)
          newMerge.forEach(async(CadData)=>{
              let obj= {
                  EmployeeCode:'',
                  EmployeeName:'',
                  BranchCode:'',
                  BranchName:'',
                  SalesOfficeCode:'',
                  SalesOfficeName:'',
                  ConstructionCode:[],
                  // ConstructionTypeName:'',
                  SentProcessRequestDate:'',
                  Year:'',
                  Month:'',
                  KakouIrai:0,
                  Cad:0,
                  CadExpress:0,
                  CadPhase1:0,
                  CadFinal:0,
                  CadPdr:0,
                  Denki:0,
                  DenkiExpress:0,
                  ExternalPers:0,
                  House_presentation:0,
                  SI_JIKU:0,
                  SI_WAKU:0,
                  ALVS:0,
                  JoutouDays:0,
                  DecidedLandDays:0,
                  FinalPlan:0,
                  StopCheck:0,
              }
              for(let i=0;i<=CadData.length-1;i++){  
                  obj.EmployeeCode =CadData[0].EmployeeCode
                  let index = newR.findIndex(x=> x.EmployeeCode == obj.EmployeeCode)
                  obj.EmployeeName = index  > - 1 ? newR[index].EmployeeName  : ''
                  obj.BranchCode = index  > - 1 ? newR[index].ContractExhibitionCode : ''
                  obj.BranchName = index  > - 1 ? newR[index].ContractExhibitionName : ''
                  obj.SalesOfficeCode = index  > - 1 ? newR[index].SalesOfficeCode : ''
                  obj.SalesOfficeName = index  > - 1 ? newR[index].SalesOfficeName : ''
                  obj.Sort = index  > - 1 ? newR[index].sort : ''
                  if(CadData[i].ConstructionCode ){
                      obj.KakouIrai +=1
                      obj.ConstructionCode.push({
                        ConstructionCode:CadData[i].ConstructionCode,
                        ConstructionTypeName:CadData[i].ConstructionTypeName,
                        SentProcessRequestDate:CadData[i].SentProcessRequestDate,
                        Cad:parseInt(CadData[i].cad ? CadData[i].cad:0),
                        CadExpress:parseInt(CadData[i].CadExpress ? CadData[i].CadExpress:0),
                        CadPhase1:parseInt(CadData[i].CadPhase1 ? CadData[i].CadPhase1:0),
                        CadFinal:parseInt(CadData[i].CadFinalPlan ? CadData[i].CadFinalPlan:0),
                        CadPdr:parseInt(CadData[i].CadPdr ? CadData[i].CadPdr:0),
                        Denki:parseInt(CadData[i].denki ? CadData[i].denki:0),
                        DenkiExpress:parseInt(CadData[i].denkiExpress ? CadData[i].denkiExpress:0),
                        ExternalPers:parseInt(CadData[i].ExternalPers ? CadData[i].ExternalPers:0),
                        House_presentation:parseInt(CadData[i].House_presentation ? CadData[i].House_presentation:0),
                        SI_JIKU:parseInt(CadData[i].SI_WAKU_DUCT == 0 ? CadData[i].SI_JIKU:0),
                        SI_WAKU:parseInt(CadData[i].SI_WAKU_DUCT ? CadData[i].SI_WAKU_DUCT:0),
                        ALVS:parseInt(CadData[i].ALVS ? CadData[i].ALVS:0),
                        JoutouDays:parseInt(CadData[i].JoutouDays ? CadData[i].JoutouDays:0),
                        DecidedLandDays:parseInt(CadData[i].decidedlandDays ? CadData[i].decidedlandDays:0),
                        FinalPlan:parseInt(CadData[i].finalPlan ? CadData[i].finalPlan:0),
                        StopCheck:parseInt(CadData[i].stopcheck ? CadData[i].stopcheck:0)
                      })
                  }
                  obj.Cad+=parseInt(CadData[i].cad ? CadData[i].cad:0)
                  obj.CadExpress+=parseInt(CadData[i].CadExpress ? CadData[i].CadExpress:0)
                  obj.CadPhase1+=parseInt(CadData[i].CadPhase1 ? CadData[i].CadPhase1:0),
                  obj.CadFinal+=parseInt(CadData[i].CadFinalPlan ? CadData[i].CadFinalPlan:0),
                  obj.CadPdr+=parseInt(CadData[i].CadPdr ? CadData[i].CadPdr:0),
                  obj.Denki+=parseInt(CadData[i].denki ? CadData[i].denki:0)
                  obj.DenkiExpress+=parseInt(CadData[i].denkiExpress ? CadData[i].denkiExpress:0)
                  obj.ExternalPers+=parseInt(CadData[i].ExternalPers ? CadData[i].ExternalPers:0)
                  obj.House_presentation+=parseInt(CadData[i].House_presentation ? CadData[i].House_presentation:0)
                  obj.SI_JIKU+=parseInt(CadData[i].SI_WAKU_DUCT == 0 ? CadData[i].SI_JIKU:0)
                  obj.SI_WAKU+=parseInt(CadData[i].SI_WAKU_DUCT ? CadData[i].SI_WAKU_DUCT:0)
                  obj.ALVS+=parseInt(CadData[i].ALVS ? CadData[i].ALVS:0)
                  obj.JoutouDays+=parseInt(CadData[i].JoutouDays ? CadData[i].JoutouDays:0)
                  obj.DecidedLandDays+=parseInt(CadData[i].decidedlandDays ? CadData[i].decidedlandDays:0)
                  obj.FinalPlan+=parseInt(CadData[i].finalPlan ? CadData[i].finalPlan:0)
                  obj.StopCheck+=parseInt(CadData[i].stopcheck ? CadData[i].stopcheck:0)
              }
              if(obj.SalesOfficeCode && obj.SalesOfficeName !='展示場'){  
                this.firstProcess.push(obj)
              }
              else if(!obj.SalesOfficeCode && (obj.ContractExhibitionCode && obj.ContractExhibitionCode)){  
                this.firstProcess.push(obj)
              }
          })
//================================================= Merge 2 array from TH and Cad=========================================
          let newTest =  this.thArr.map((item)=>{
          let index = this.firstProcess.findIndex(x=> x.EmployeeCode === item.EmployeeCode) 
            if(index > -1 ){
                    item.NewConstruction =item.ConstructionCode.map((value) => {
                            let index2 =  this.firstProcess[index].ConstructionCode.findIndex(c=> c.ConstructionCode == value.ConstructionCode)
                            if(index2 > -1 ){
                              return   {
                                BranchCode:this.firstProcess[index].BranchCode,
                                BranchName:this.firstProcess[index].BranchName,
                                ConstructionCode:value.ConstructionCode,
                                ConstructionTypeName:value.ConstructionTypeName,
                                SentProcessRequestDate:value.SentProcessRequestDate,
                                EmployeeCode:this.firstProcess[index].EmployeeCode,
                                EmployeeName:this.firstProcess[index].EmployeeName,
                                SalesOfficeCode:this.firstProcess[index].SalesOfficeCode,
                                SalesOfficeName:this.firstProcess[index].SalesOfficeName,
                                Year:moment(value.SentProcessRequestDate).format('YYYY'),
                                Month:moment(value.SentProcessRequestDate).format('MM'),
                                TH:value.TH,
                                Cad:this.firstProcess[index].ConstructionCode[index2].Cad,
                                CadExpress:this.firstProcess[index].ConstructionCode[index2].CadExpress,
                                CadPhase1:this.firstProcess[index].ConstructionCode[index2].CadPhase1,
                                CadFinal:this.firstProcess[index].ConstructionCode[index2].CadFinal,
                                CadPdr:this.firstProcess[index].ConstructionCode[index2].CadPdr,
                                Denki:this.firstProcess[index].ConstructionCode[index2].Denki,
                                DenkiExpress:this.firstProcess[index].ConstructionCode[index2].DenkiExpress,
                                ExternalPers:this.firstProcess[index].ConstructionCode[index2].ExternalPers,
                                House_presentation:this.firstProcess[index].ConstructionCode[index2].House_presentation,
                                SI_JIKU:this.firstProcess[index].ConstructionCode[index2].SI_JIKU,
                                SI_WAKU:this.firstProcess[index].ConstructionCode[index2].SI_WAKU,
                                ALVS:this.firstProcess[index].ConstructionCode[index2].ALVS,
                                JoutouDays:this.firstProcess[index].ConstructionCode[index2].JoutouDays,
                                DecidedLandDays:this.firstProcess[index].ConstructionCode[index2].DecidedLandDays,
                                FinalPlan:this.firstProcess[index].ConstructionCode[index2].FinalPlan,
                                StopCheck:this.firstProcess[index].ConstructionCode[index2].StopCheck
                              };
                            }else{
                              return{
                                BranchCode:this.firstProcess[index].BranchCode,
                                BranchName:this.firstProcess[index].BranchName,
                                ConstructionCode:value.ConstructionCode,
                                ConstructionTypeName:value.ConstructionTypeName,
                                SentProcessRequestDate:value.SentProcessRequestDate,
                                EmployeeCode:this.firstProcess[index].EmployeeCode,
                                EmployeeName:this.firstProcess[index].EmployeeName,
                                SalesOfficeCode:this.firstProcess[index].SalesOfficeCode,
                                SalesOfficeName:this.firstProcess[index].SalesOfficeName,
                                Year:moment(value.SentProcessRequestDate).format('YYYY'),
                                Month:moment(value.SentProcessRequestDate).format('MM'),
                                TH:value.TH,
                                Cad:0,
                                CadExpress:0,
                                CadPhase1:0,
                                CadFinal:0,
                                CadPdr:0,
                                Denki:0,
                                DenkiExpress:0,
                                ExternalPers:0,
                                House_presentation:0,
                                SI_JIKU:0,
                                SI_WAKU:0,
                                ALVS:0,
                                JoutouDays:0,
                                DecidedLandDays:0,
                                FinalPlan:0,
                                StopCheck:0
                              };

                            }
                    },[])
                  item.CadConstructionCode = this.firstProcess[index].ConstructionCode 
                  item.KakouIrai =this.firstProcess[index].KakouIrai,
                  item.Cad=this.firstProcess[index].Cad,
                  item.CadExpress=this.firstProcess[index].CadExpress,
                  item.CadPhase1=this.firstProcess[index].CadPhase1,
                  item.CadFinal=this.firstProcess[index].CadFinal,
                  item.CadPdr=this.firstProcess[index].CadPdr,
                  item.Denki=this.firstProcess[index].Denki,
                  item.DenkiExpress=this.firstProcess[index].DenkiExpress,
                  item.ExternalPers=this.firstProcess[index].ExternalPers,
                  item.House_presentation=this.firstProcess[index].House_presentation,
                  item.SI_JIKU=this.firstProcess[index].SI_JIKU,
                  item.SI_WAKU=this.firstProcess[index].SI_WAKU,
                  item.ALVS=this.firstProcess[index].ALVS,
                  item.JoutouDays=this.firstProcess[index].JoutouDays,
                  item.DecidedLandDays=this.firstProcess[index].DecidedLandDays,
                  item.FinalPlan=this.firstProcess[index].FinalPlan,
                  item.StopCheck=this.firstProcess[index].StopCheck
                  return item
          }
          else
          {
              item.NewConstruction = item.ConstructionCode.map(value=>{
                return{
                  ConstructionCode:value.ConstructionCode,
                  ConstructionTypeName:value.ConstructionTypeName,
                  SentProcessRequestDate:value.SentProcessRequestDate,
                  Year:moment(value.SentProcessRequestDate).format('YYYY'),
                  Month:moment(value.SentProcessRequestDate).format('MM'),
                  TH:value.TH,
                  Cad:0,
                  CadExpress:0,
                  CadPhase1:0,
                  CadFinal:0,
                  CadPdr:0,
                  Denki:0,
                  DenkiExpress:0,
                  ExternalPers:0,
                  House_presentation:0,
                  SI_JIKU:0,
                  SI_WAKU:0,
                  ALVS:0,
                  JoutouDays:0,
                  DecidedLandDays:0,
                  FinalPlan:0,
                  StopCheck:0
                };
              })
              item.Cad=0
              item.CadExpress=0
              item.CadPhase1=0
              item.CadFinal=0
              item.CadPdr=0
              item.Denki=0
              item.DenkiExpress=0
              item.ExternalPers=0
              item.House_presentation=0
              item.SI_JIKU=0
              item.SI_WAKU=0
              item.ALVS=0
              item.JoutouDays=0
              item.DecidedLandDays=0
              item.FinalPlan=0
              item.StopCheck=0
              return item
            }
        },[])
        this.tempFinalData = newTest.map(x=>{return x.NewConstruction}).flat();

        //==========================Get CadDetails======================================

        // let CadDatas = this.tempFinalData;
        // const propertiesToCalculate = ['CadFinal', 'CadPhase1', 'CadPdr'];
        // const totalsBySalesOffice = [];
        // CadDatas.forEach(item => {
        //   const salesOfficeName = item.SalesOfficeName;
        //   if (salesOfficeName !== '展示場') {
        //     let salesOfficeIndex = totalsBySalesOffice.findIndex(
        //       office => office && office.SalesOfficeName === salesOfficeName
        //     );
        //     if (salesOfficeIndex === -1) {
        //       const newEntry = {
        //         SalesOfficeName: salesOfficeName,
        //         CadFinal: 0,
        //         CadPhase1: 0,
        //         CadPdr: 0
        //       };
        //       salesOfficeIndex = totalsBySalesOffice.push(newEntry) - 1;
        //     }
        //     propertiesToCalculate.forEach(propertyName => {
        //       totalsBySalesOffice[salesOfficeIndex][propertyName] += item[propertyName];
        //     });
        //   }
        // });
        // this.CadDetails = totalsBySalesOffice;

        //================================================================
          this.getAverage(this.tempFinalData)
          // this.sort()
//================================================================
            }).catch(err=>{
              if( err.response  && (err.response.status == 403 || err.response.status == 401)){
                  this.logout()
              }else{
                console.log(err,'error')
              }
              this.loading =false
              return false;
            })
        }).catch(err=>{
          if( err.response  && (err.response.status == 403 || err.response.status == 401)){
              this.logout()
          }else{
            console.log(err,'error')
          }
          this.loading =false
          return false;
        })
      },
      getAverage(data){
        this.nationalDataExist = false
        let StandardPlans = ['i-smile(旧)', 'i-smile(2×6)', 'i-smileⅡ', '新i-smile(建具変更)⇒当面発表無し', '新i-smile＋(建具変更)⇒当面発表無し', 'i-smile＋', 'i-smileⅡ＋', 'HUGme(ハグミー)', 'HUGme fam(ハグミー･ファム)'];
        let filteredData;
        if (this.buildingType === 'StandardPlan') {
          filteredData = data.filter(item => StandardPlans.includes(item.ConstructionTypeName))
        } else if (this.buildingType === 'NonStandardPlan') {
          filteredData = data.filter(item => !StandardPlans.includes(item.ConstructionTypeName))
        } else {
          filteredData = data;
        }
        this.RankingData = filteredData.reduce((total,item)=>{
          if(item.SalesOfficeCode != null && item.SalesOfficeCode !== '展示場'){
            let index = total.findIndex(x=> this.gatherby == '1' ? x.EmployeeCode == item.EmployeeCode : x.SalesOfficeCode == item.SalesOfficeCode)
            if(index > -1){
              total[index].KakouIrai += item.ConstructionCode ? 1 : 0,
              total[index].SI_JIKU_Total += item.SI_JIKU == 0 ? 0 : 1,
              total[index].SI_WAKU_Total += item.SI_WAKU == 0 ? 0 : 1,
              total[index].Cad_Total += item.Cad ? 1 : 0,
              total[index].CadExpress_Total += item.CadExpress ? 1 : 0,
              total[index].CadPhase1_Total += item.CadPhase1 ? 1 : 0,
              total[index].CadPdr_Total += item.CadPdr  ? 1 : 0,
              total[index].CadFinal_Total += item.CadFinal ? 1 : 0,
              total[index].Denki_Total += item.Denki ? 1 : 0,
              total[index].DenkiExpress_Total += item.DenkiExpress ? 1 : 0,
              total[index].ExternalPers_Total += item.ExternalPers ? 1 : 0,
              total[index].House_presentation_Total += item.House_presentation ? 1 : 0,
              total[index].ALVS_Total += item.ALVS ? 1 : 0,
              total[index].JoutouDays_Total += item.JoutouDays ? 1 : 0,
              total[index].DecidedLandDays_Total += item.DecidedLandDays ? 1 : 0,
              total[index].FinalPlan_Total += item.FinalPlan ? 1 : 0,
              total[index].StopCheck_Total += item.StopCheck ? 1 : 0,
              total[index].TH += item.TH
              total[index].Cad += item.Cad
              total[index].CadExpress += item.CadExpress
              total[index].CadPhase1 += item.CadPhase1
              total[index].CadFinal += item.CadFinal
              total[index].CadPdr += item.CadPdr
              total[index].Denki += item.Denki
              total[index].DenkiExpress += item.DenkiExpress
              total[index].ExternalPers += item.ExternalPers
              total[index].House_presentation += item.House_presentation
              total[index].SI_JIKU += item.SI_JIKU
              total[index].SI_WAKU += item.SI_WAKU
              total[index].ALVS += item.ALVS
              total[index].JoutouDays += item.JoutouDays
              total[index].DecidedLandDays += item.DecidedLandDays
              total[index].FinalPlan += item.FinalPlan
              total[index].StopCheck += item.StopCheck ? item.StopCheck : 0
              let index2 =  total[index].data.findIndex(y=> y.Year == item.Year && y.Month==item.Month)
              if(index2 > -1){
                total[index].data[index2].KakouIrai += item.ConstructionCode ? 1 : 0
                total[index].data[index2].SI_JIKU_Total += item.SI_JIKU == 0 ? 0 : 1,
                total[index].data[index2].SI_WAKU_Total += item.SI_WAKU == 0 ? 0 : 1,
                total[index].data[index2].Cad_Total += item.Cad ? 1 : 0,
                total[index].data[index2].CadExpress_Total += item.CadExpress ? 1 : 0,
                total[index].data[index2].CadPhase1_Total += item.CadPhase1 ? 1 : 0,
                total[index].data[index2].CadPdr_Total += item.CadPdr ? 1 : 0,
                total[index].data[index2].CadFinal_Total += item.CadFinal ? 1 : 0,
                total[index].data[index2].Denki_Total += item.Denki ? 1 : 0,
                total[index].data[index2].DenkiExpress_Total += item.DenkiExpress ? 1 : 0,
                total[index].data[index2].ExternalPers_Total += item.ExternalPers ? 1 : 0,
                total[index].data[index2].House_presentation_Total += item.House_presentation ? 1 : 0,
                total[index].data[index2].ALVS_Total += item.ALVS ? 1 : 0,
                total[index].data[index2].JoutouDays_Total += item.JoutouDays ? 1 : 0,
                total[index].data[index2].DecidedLandDays_Total += item.DecidedLandDays ? 1 : 0,
                total[index].data[index2].FinalPlan_Total += item.FinalPlan ? 1 : 0,
                total[index].data[index2].StopCheck_Total += item.StopCheck ? 1 : 0,
                total[index].data[index2].TH += item.TH
                total[index].data[index2].Cad += item.Cad
                total[index].data[index2].CadExpress += item.CadExpress
                total[index].data[index2].CadPhase1 += item.CadPhase1
                total[index].data[index2].CadFinal += item.CadFinal
                total[index].data[index2].CadPdr += item.CadPdr
                total[index].data[index2].Denki += item.Denki
                total[index].data[index2].DenkiExpress += item.DenkiExpress
                total[index].data[index2].ExternalPers += item.ExternalPers
                total[index].data[index2].House_presentation += item.House_presentation
                total[index].data[index2].SI_JIKU += item.SI_JIKU
                total[index].data[index2].SI_WAKU += item.SI_WAKU
                total[index].data[index2].ALVS += item.ALVS
                total[index].data[index2].JoutouDays += item.JoutouDays
                total[index].data[index2].DecidedLandDays += item.DecidedLandDays
                total[index].data[index2].FinalPlan += item.FinalPlan
                total[index].data[index2].StopCheck += item.StopCheck
              }else{
                total[index].data.push({
                  KakouIrai: item.ConstructionCode ? 1 : 0,
                  SI_JIKU_Total: item.SI_JIKU == 0 ? 0 : 1,
                  SI_WAKU_Total: item.SI_WAKU == 0 ? 0 : 1,
                  Cad_Total: item.Cad ? 1 : 0,
                  CadExpress_Total: item.CadExpress ? 1 : 0,
                  CadPhase1_Total: item.CadPhase1 ? 1 : 0,
                  CadPdr_Total: item.CadPdr ? 1 : 0,
                  CadFinal_Total: item.CadFinal ? 1 : 0,
                  Denki_Total: item.Denki ? 1 : 0,
                  DenkiExpress_Total: item.DenkiExpress ? 1 : 0,
                  ExternalPers_Total: item.ExternalPers ? 1 : 0,
                  House_presentation_Total: item.House_presentation ? 1 : 0,
                  ALVS_Total: item.ALVS ? 1 : 0,
                  JoutouDays_Total: item.JoutouDays ? 1 : 0,
                  DecidedLandDays_Total: item.DecidedLandDays ? 1 : 0,
                  FinalPlan_Total: item.FinalPlan ? 1 : 0,
                  StopCheck_Total: item.StopCheck ? 1 : 0,
                  Year:item.Year,
                  Month:item.Month,
                  TH:item.TH,
                  Cad:item.Cad,
                  CadExpress:item.CadExpress,
                  CadPhase1:item.CadPhase1,
                  CadFinal:item.CadFinal,
                  CadPdr:item.CadPdr,
                  Denki:item.Denki,
                  DenkiExpress:item.DenkiExpress,
                  ExternalPers:item.ExternalPers,
                  House_presentation:item.House_presentation,
                  SI_JIKU:item.SI_JIKU,
                  SI_WAKU:item.SI_WAKU,
                  ALVS:item.ALVS,
                  JoutouDays:item.JoutouDays,
                  DecidedLandDays:item.DecidedLandDays,
                  FinalPlan:item.FinalPlan,
                  StopCheck:item.StopCheck,
                })
              }
              return total
            }else{
              total.push({
                BranchCode:item.BranchCode,
                BranchName:item.BranchName,
                EmployeeCode:item.EmployeeCode,
                EmployeeName:item.EmployeeName,
                SalesOfficeCode:item.SalesOfficeCode,
                SalesOfficeName:item.SalesOfficeName,
                KakouIrai: item.ConstructionCode ? 1 : 0,
                SI_JIKU_Total: item.SI_JIKU == 0 ? 0 : 1,
                SI_WAKU_Total: item.SI_WAKU == 0 ? 0 : 1,
                Cad_Total: item.Cad ? 1 : 0,
                CadExpress_Total: item.CadExpress ? 1 : 0,
                CadPhase1_Total: item.CadPhase1 ? 1 : 0,
                CadPdr_Total: item.CadPdr ? 1 : 0,
                CadFinal_Total: item.CadFinal ? 1 : 0,
                Denki_Total: item.Denki ? 1 : 0,
                DenkiExpress_Total: item.DenkiExpress ? 1 : 0,
                ExternalPers_Total: item.ExternalPers ? 1 : 0,
                House_presentation_Total: item.House_presentation ? 1 : 0,
                ALVS_Total: item.ALVS ? 1 : 0,
                JoutouDays_Total: item.JoutouDays ? 1 : 0,
                DecidedLandDays_Total: item.DecidedLandDays ? 1 : 0,
                FinalPlan_Total: item.FinalPlan ? 1 : 0,
                StopCheck_Total: item.StopCheck ? 1 : 0,
                TH:item.TH,
                Cad:item.Cad,
                CadExpress:item.CadExpress,
                CadPhase1:item.CadPhase1,
                CadFinal:item.CadFinal,
                CadPdr:item.CadPdr,
                Denki:item.Denki,
                DenkiExpress:item.DenkiExpress,
                ExternalPers:item.ExternalPers,
                House_presentation:item.House_presentation,
                SI_JIKU:item.SI_JIKU,
                SI_WAKU:item.SI_WAKU,
                ALVS:item.ALVS,
                JoutouDays:item.JoutouDays,
                DecidedLandDays:item.DecidedLandDays,
                FinalPlan:item.FinalPlan,
                StopCheck:item.StopCheck,
                data:[{
                  KakouIrai: item.ConstructionCode ? 1 : 0,
                  SI_JIKU_Total: item.SI_JIKU == 0 ? 0 : 1,
                  SI_WAKU_Total: item.SI_WAKU == 0 ? 0 : 1,
                  Cad_Total: item.Cad ? 1 : 0,
                  CadExpress_Total: item.CadExpress ? 1 : 0,
                  CadPhase1_Total: item.CadPhase1 ? 1 : 0,
                  CadPdr_Total: item.CadPdr ? 1 : 0,
                  CadFinal_Total: item.CadFinal ? 1 : 0,
                  Denki_Total: item.Denki ? 1 : 0,
                  DenkiExpress_Total: item.DenkiExpress ? 1 : 0,
                  ExternalPers_Total: item.ExternalPers ? 1 : 0,
                  House_presentation_Total: item.House_presentation ? 1 : 0,
                  ALVS_Total: item.ALVS ? 1 : 0,
                  JoutouDays_Total: item.JoutouDays ? 1 : 0,
                  DecidedLandDays_Total: item.DecidedLandDays ? 1 : 0,
                  FinalPlan_Total: item.FinalPlan ? 1 : 0,
                  StopCheck_Total: item.StopCheck ? 1 : 0,
                  Year:item.Year,
                  Month:item.Month,
                  TH:item.TH,
                  Cad:item.Cad,
                  CadExpress:item.CadExpress,
                  CadPhase1:item.CadPhase1,
                  CadFinal:item.CadFinal,
                  CadPdr:item.CadPdr,
                  Denki:item.Denki,
                  DenkiExpress:item.DenkiExpress,
                  ExternalPers:item.ExternalPers,
                  House_presentation:item.House_presentation,
                  SI_JIKU:item.SI_JIKU,
                  SI_WAKU:item.SI_WAKU,
                  ALVS:item.ALVS,
                  JoutouDays:item.JoutouDays,
                  DecidedLandDays:item.DecidedLandDays,
                  FinalPlan:item.FinalPlan,
                  StopCheck:item.StopCheck,
                }]
              })
              return total
            }
          }else{
            return total;
          }
          },[]).filter((x)=>{
            x.data.sort((a, b) => {
              if(a.Year !== b.Year) {
                return a.Year - b.Year;
              }else{
                return a.Month - b.Month;
              }
            });
            x.Quarterly = [
              { Year:'FirstQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
              { Year:'SecondQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
              { Year:'ThirdQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
              { Year:'FourthQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0}
            ]
            x.HalfYear = [
              {  Year:'FirstHalf',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
              {  Year:'SecondHalf',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadExpress:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0}
            ]
            x.data.forEach((d,i)=>{
              const quarterIndex = i >= 0 && i <= 3 ? 0 : i >= 4 && i <= 6 ? 1 : i >= 7 && i <= 9 ? 2 : 3;
              const quarterLabel = ['FirstQuarter', 'SecondQuarter', 'ThirdQuarter', 'FourthQuarter'][quarterIndex];
              //  x.Quarterly[`${i>=0 && i <=3 ? 0 : i >=4 && i <= 6 ? 1 : i >=7 && i <=9 ? 2: 3}` ].Year  = i>=0 && i <=3 ? `${x.data[0].Month}月~${x.data[3].Month}月`: i >=4 && i <= 6 ? `${x.data[4].Month}月~${x.data[6].Month}月` : i >=7 && i <=9 ? `${x.data[7].Month}月~${x.data[9].Month}月`: `${x.data[10].Month}月~${x.data[12].Month}月`
              x.Quarterly[quarterIndex].Year = quarterLabel
              x.Quarterly[quarterIndex].KakouIrai += d.KakouIrai
              x.Quarterly[quarterIndex].SI_JIKU_Total += d.SI_JIKU_Total
              x.Quarterly[quarterIndex].SI_WAKU_Total += d.SI_WAKU_Total
              x.Quarterly[quarterIndex].Cad_Total += d.Cad_Total
              x.Quarterly[quarterIndex].CadExpress_Total += d.CadExpress_Total
              x.Quarterly[quarterIndex].CadPhase1_Total += d.CadPhase1_Total
              x.Quarterly[quarterIndex].CadPdr_Total += d.CadPdr_Total
              x.Quarterly[quarterIndex].CadFinal_Total += d.CadFinal_Total
              x.Quarterly[quarterIndex].Denki_Total += d.Denki_Total
              x.Quarterly[quarterIndex].DenkiExpress_Total += d.DenkiExpress_Total
              x.Quarterly[quarterIndex].ExternalPers_Total += d.ExternalPers_Total
              x.Quarterly[quarterIndex].House_presentation_Total += d.House_presentation_Total
              x.Quarterly[quarterIndex].ALVS_Total += d.ALVS_Total
              x.Quarterly[quarterIndex].JoutouDays_Total += d.JoutouDays_Total
              x.Quarterly[quarterIndex].DecidedLandDays_Total += d.DecidedLandDays_Total
              x.Quarterly[quarterIndex].FinalPlan_Total += d.FinalPlan_Total
              x.Quarterly[quarterIndex].StopCheck_Total += d.StopCheck_Total
              x.Quarterly[quarterIndex].TH += d.TH
              x.Quarterly[quarterIndex].Cad += d.Cad
              x.Quarterly[quarterIndex].CadExpress += d.CadExpress
              x.Quarterly[quarterIndex].CadPhase1 += d.CadPhase1,
              x.Quarterly[quarterIndex].CadFinal += d.CadFinal,
              x.Quarterly[quarterIndex].CadPdr += d.CadPdr,
              x.Quarterly[quarterIndex].Denki += d.Denki
              x.Quarterly[quarterIndex].DenkiExpress += d.DenkiExpress
              x.Quarterly[quarterIndex].ExternalPers += d.ExternalPers
              x.Quarterly[quarterIndex].House_presentation += d.House_presentation
              x.Quarterly[quarterIndex].SI_JIKU += d.SI_JIKU
              x.Quarterly[quarterIndex].SI_WAKU += d.SI_WAKU
              x.Quarterly[quarterIndex].ALVS += d.ALVS
              x.Quarterly[quarterIndex].JoutouDays += d.JoutouDays
              x.Quarterly[quarterIndex].DecidedLandDays += d.DecidedLandDays
              x.Quarterly[quarterIndex].FinalPlan += d.FinalPlan
              x.Quarterly[quarterIndex].StopCheck += d.StopCheck
              
              // x.HalfYear[`${i>=0 && i <=6 ? 0 : i >=7 && i <= 12 ? 1 :''}` ].Year =  i>=0 && i <=6 ? `${x.data[0].Month}月~${x.data[6].Month}月` : i >=7 && i <= 12 ? `${x.data[7].Month}月~${x.data[12].Month}月` :''
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].Year =  i>=0 && i <=6 ? `FirstHalf` : `SecondHalf`
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].KakouIrai += d.KakouIrai
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].SI_JIKU_Total += d.SI_JIKU_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].SI_WAKU_Total += d.SI_WAKU_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].Cad_Total += d.Cad_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadExpress_Total += d.CadExpress_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadPhase1_Total += d.CadPhase1_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadPdr_Total += d.CadPdr_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadFinal_Total += d.CadFinal_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].Denki_Total += d.Denki_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].DenkiExpress_Total += d.DenkiExpress_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].ExternalPers_Total += d.ExternalPers_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].House_presentation_Total += d.House_presentation_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].ALVS_Total += d.ALVS_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].JoutouDays_Total += d.JoutouDays_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].DecidedLandDays_Total += d.DecidedLandDays_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].FinalPlan_Total += d.FinalPlan_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].StopCheck_Total += d.StopCheck_Total
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].TH += d.TH
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].Cad += d.Cad
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadExpress += d.CadExpress
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadPhase1 += d.CadPhase1,
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadFinal += d.CadFinal,
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].CadPdr += d.CadPdr,
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].Denki += d.Denki
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].DenkiExpress += d.DenkiExpress
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].ExternalPers += d.ExternalPers
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].House_presentation += d.House_presentation
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].SI_JIKU += d.SI_JIKU
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].SI_WAKU += d.SI_WAKU
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].ALVS += d.ALVS
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].JoutouDays += d.JoutouDays
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].DecidedLandDays += d.DecidedLandDays
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].FinalPlan += d.FinalPlan
              x.HalfYear[`${i>=0 && i <=6 ? 0 : 1}` ].StopCheck += d.StopCheck

              d.THAvg = d.TH != 0 && d.KakouIrai != 0 ?  this.tofixed2((d.TH / d.KakouIrai)) : '―'
              d.CadAvg = d.Cad != 0 && d.Cad_Total != 0 ?  this.tofixed2((d.Cad / d.Cad_Total)) : '―'
              d.CadExpressAvg = d.CadExpress != 0 && d.CadExpress_Total != 0 ?  this.tofixed2((d.CadExpress / d.CadExpress_Total)) : '―'
              d.CadPhase1Avg = d.CadPhase1 != 0 && d.CadPhase1_Total != 0 ?  this.tofixed2((d.CadPhase1 / d.CadPhase1_Total)) : '―'
              d.CadPdrAvg = d.CadPdr != 0 && d.CadPdr_Total != 0 ?  this.tofixed2((d.CadPdr / d.CadPdr_Total)) : '―'
              d.CadFinalAvg = d.CadFinal != 0 && d.CadFinal_Total != 0 ?  this.tofixed2((d.CadFinal / d.CadFinal_Total)) : '―'
              d.DenkiAvg= d.Denki != 0 && d.Denki_Total != 0 ?  this.tofixed2((d.Denki / d.Denki_Total)) : '―'
              d.DenkiExpressAvg= d.DenkiExpress != 0 && d.DenkiExpress_Total != 0 ?  this.tofixed2((d.DenkiExpress / d.DenkiExpress_Total)) : '―'
              d.ExternalPersAvg = d.ExternalPers != 0 && d.ExternalPers_Total != 0 ?  this.tofixed2((d.ExternalPers / d.ExternalPers_Total)) : '―'
              d.House_presentationAvg = d.House_presentation != 0 && d.House_presentation_Total != 0 ?  this.tofixed2((d.House_presentation / d.House_presentation_Total)) : '―'
              d.SI_JIKUAvg =  d.SI_JIKU != 0 && d.SI_JIKU_Total != 0 ?  this.tofixed2((d.SI_JIKU / d.SI_JIKU_Total)) : '―'
              d.SI_WAKUAvg =  d.SI_WAKU != 0 && d.SI_WAKU_Total != 0 ?  this.tofixed2((d.SI_WAKU / d.SI_WAKU_Total)) : '―'
              d.ALVSAvg =  d.ALVS != 0 && d.ALVS_Total != 0 ?  this.tofixed2((d.ALVS / d.ALVS_Total)) : '―'
              d.JoutouDaysAvg = d.JoutouDays != 0 && d.JoutouDays_Total != 0 ?  this.tofixed2((d.JoutouDays / d.JoutouDays_Total)) : '―'
              d.DecidedLandDaysAvg =  d.DecidedLandDays != 0 && d.DecidedLandDays_Total != 0 ?  this.tofixed2((d.DecidedLandDays / d.DecidedLandDays_Total)) : '―'
              d.FinalPlanAvg = d.FinalPlan != 0 && d.FinalPlan_Total != 0 ?  this.tofixed2((d.FinalPlan / d.FinalPlan_Total)) : '―'
              d.StopCheckAvg =  d.StopCheck != 0 &&  d.StopCheck_Total != 0 ?  this.tofixed2((d.StopCheck / d.StopCheck_Total)) : '―'
            })
            x.THAvg = x.TH != 0 && x.KakouIrai != 0 ?  this.tofixed2((x.TH / x.KakouIrai)) : 0
            x.CadAvg =  this.tofixed2((x.Cad / x.Cad_Total))
            x.CadExpressAvg =  this.tofixed2((x.CadExpress / x.CadExpress_Total))
            x.CadPhase1Avg = this.tofixed2((x.CadPhase1 / x.CadPhase1_Total))
            x.CadPdrAvg = this.tofixed2((x.CadPdr / x.CadPdr_Total))
            x.CadFinalAvg = this.tofixed2((x.CadFinal / x.CadFinal_Total))
            x.DenkiAvg=this.tofixed2((x.Denki / x.Denki_Total))
            x.DenkiExpressAvg=this.tofixed2((x.DenkiExpress / x.DenkiExpress_Total))
            x.ExternalPersAvg = this.tofixed2((x.ExternalPers / x.ExternalPers_Total))
            x.House_presentationAvg = this.tofixed2((x.House_presentation / x.House_presentation_Total))
            x.SI_JIKUAvg = this.tofixed2((x.SI_JIKU / x.SI_JIKU_Total))
            x.SI_WAKUAvg = this.tofixed2((x.SI_WAKU / x.SI_WAKU_Total))
            x.ALVSAvg = this.tofixed2((x.ALVS / x.ALVS_Total))
            x.JoutouDaysAvg = this.tofixed2((x.JoutouDays / x.JoutouDays_Total))
            x.DecidedLandDaysAvg = this.tofixed2((x.DecidedLandDays / x.DecidedLandDays_Total))
            x.FinalPlanAvg =this.tofixed2((x.FinalPlan / x.FinalPlan_Total))
            x.StopCheckAvg = this.tofixed2((x.StopCheck / x.StopCheck_Total))
            x.Quarterly.filter(d=>{
              d.THAvg = d.TH != 0 && d.KakouIrai != 0 ?  this.tofixed2((d.TH / d.KakouIrai)) : 0
              d.CadAvg =  this.tofixed2((d.Cad / d.Cad_Total))
              d.CadExpressAvg =  this.tofixed2((d.CadExpress / d.CadExpress_Total))
              d.CadPhase1Avg = this.tofixed2((d.CadPhase1 / d.CadPhase1_Total))
              d.CadPdrAvg = this.tofixed2((d.CadPdr / d.CadPdr_Total))
              d.CadFinalAvg = this.tofixed2((d.CadFinal / d.CadFinal_Total))
              d.DenkiAvg=this.tofixed2((d.Denki / d.Denki_Total))
              d.DenkiExpressAvg=this.tofixed2((d.DenkiExpress / d.DenkiExpress_Total))
              d.ExternalPersAvg = this.tofixed2((d.ExternalPers / d.ExternalPers_Total))
              d.House_presentationAvg = this.tofixed2((d.House_presentation / d.House_presentation_Total))
              d.SI_JIKUAvg = this.tofixed2((d.SI_JIKU / d.SI_JIKU_Total))
              d.SI_WAKUAvg = this.tofixed2((d.SI_WAKU / d.SI_WAKU_Total))
              d.ALVSAvg = this.tofixed2((d.ALVS / d.ALVS_Total))
              d.JoutouDaysAvg = this.tofixed2((d.JoutouDays / d.JoutouDays_Total))
              d.DecidedLandDaysAvg = this.tofixed2((d.DecidedLandDays / d.DecidedLandDays_Total))
              d.FinalPlanAvg =this.tofixed2((d.FinalPlan / d.FinalPlan_Total))
              d.StopCheckAvg = this.tofixed2((d.StopCheck / d.StopCheck_Total))
            })
            x.HalfYear.filter(d=>{
              d.THAvg = d.TH != 0 && d.KakouIrai != 0 ?  this.tofixed2((d.TH / d.KakouIrai)) : 0
              d.CadAvg =  this.tofixed2((d.Cad / d.Cad_Total))
              d.CadExpressAvg =  this.tofixed2((d.CadExpress / d.CadExpress_Total))
              d.CadPhase1Avg = this.tofixed2((d.CadPhase1 / d.CadPhase1_Total))
              d.CadPdrAvg = this.tofixed2((d.CadPdr / d.CadPdr_Total))
              d.CadFinalAvg = this.tofixed2((d.CadFinal / d.CadFinal_Total))
              d.DenkiAvg=this.tofixed2((d.Denki / d.Denki_Total))
              d.DenkiExpressAvg=this.tofixed2((d.DenkiExpress / d.DenkiExpress_Total))
              d.ExternalPersAvg = this.tofixed2((d.ExternalPers / d.ExternalPers_Total))
              d.House_presentationAvg = this.tofixed2((d.House_presentation / d.House_presentation_Total))
              d.SI_JIKUAvg = this.tofixed2((d.SI_JIKU / d.SI_JIKU_Total))
              d.SI_WAKUAvg = this.tofixed2((d.SI_WAKU / d.SI_WAKU_Total))
              d.ALVSAvg = this.tofixed2((d.ALVS / d.ALVS_Total))
              d.JoutouDaysAvg = this.tofixed2((d.JoutouDays / d.JoutouDays_Total))
              d.DecidedLandDaysAvg = this.tofixed2((d.DecidedLandDays / d.DecidedLandDays_Total))
              d.FinalPlanAvg =this.tofixed2((d.FinalPlan / d.FinalPlan_Total))
              d.StopCheckAvg = this.tofixed2((d.StopCheck / d.StopCheck_Total))
            })
          return x;
        })
        let tempPOTSelected = this.POTSelected
        this.POTSelected = 'Monthly'
        this.Header()
        this.POTSelected = tempPOTSelected
        this.setTempData()
        if(this.gatherby == '1'){
          this.GatheredSelected = 'Planners'
        }else{
          this.GatheredSelected = 'SalesOffice'
          if(!this.nationalDataExist){
            this.nationalDataExist = true
            this.setNational()
          }
        }
        this.sortHeaders()
        this.loading=false;
      },
      setBuildingCategory(data){
        this.buildingType = data
        this.getAverage(this.tempFinalData)
      },
      setPeriodOfTime(data){
        this.POTSelected = data
        this.Header()
      },
      Header(){
        // this.header.header = null
        // this.header.month=null
        this.getQuarterlyRanges(this.plannerCodeRange.fromDate, this.plannerCodeRange.toDate);
        let startMonth = moment(this.plannerCodeRange.fromDate).format('MM')
        let startYear = moment(this.plannerCodeRange.fromDate).format('YYYY')
        let endMonth = moment(this.plannerCodeRange.toDate).format('MM')
        let endYear = moment(this.plannerCodeRange.toDate).format('YYYY')
        let secondQuarter = 'SecondQuarter'
        let thirdQuarter = 'ThirdQuarter'
        let fourthQuarter = 'FourthQuarter'
        let secondHalf = 'SecondHalf'
        if(this.POTSelected =='Monthly'){
          let tempNewData = this.tempData
          let tempHead = this.RankingData.filter(d=>{
              if(d.data[0].Month != startMonth){
                d.data.unshift({
                  Year:startYear,Month:startMonth,...tempNewData
                })
              }
              if(d.data[d.data.length-1].Month != endMonth){
                d.data.push({
                  Year:endYear,Month:endMonth,...tempNewData
                })
              }
              if(d.data[0].Month != startMonth && d.data[d.data.length-1].Month != endMonth){
                d.data.unshift({
                  Year:startYear,Month:startMonth,...tempNewData
                })
                d.data.push({
                  Year:endYear,Month:endMonth,...tempNewData
                })
              }
              d.data = d.data.sort((a, b) => {
                if (a.Year === b.Year) {
                  return parseInt(a.Month) - parseInt(b.Month);
                }
                return a.Year - b.Year;
              });
              return d.data;
          })
          let indexd = -1;
          for (let i = 13; i >= 1; i--) {
              indexd = tempHead.findIndex(x => x.data.length === i);
              if (indexd !== -1) {
                  break;
              }
          }
          this.header.header = tempHead[indexd].data.reduce((total,item)=>{
            let index = total.findIndex(x=> x.Year == item.Year)
              if(index > -1 ){
                total[index].Month.push(item.Month)
                return total;
              }else{
                total.push({
                  Year:item.Year,
                  Month:[item.Month]
                })
                return total;
              }
            },[])
        }else if(this.POTSelected =='Quarterly'){
          let tempNewData = this.tempData
          let foundData;
          for (const item of this.RankingData) {
            let indexLength = item.Quarterly.length +1
            for (let i = indexLength; i <= 4; i++) {
              if (i == 2) {
                  item.Quarterly.push({ Year: secondQuarter,...tempNewData });
              } else if (i == 3) {
                  item.Quarterly.push({ Year: thirdQuarter,...tempNewData });
              } else if (i == 4) {
                  item.Quarterly.push({ Year: fourthQuarter,...tempNewData });
              }
          }
            if (item.Quarterly && item.Quarterly.every(subItem => subItem.Year && subItem.Year.trim() !== '')) {
              foundData = item;
              break; // Stop the loop once a valid item is found
            }
            return item.Quarterly;
          }
          if (foundData) {
            this.header.header = foundData.Quarterly.map(item => {
              return { Year: this.translate(item.Year) };
            });
          }
        }else if(this.POTSelected =='HalfYear'){
          let tempNewData = this.tempData
          let foundData;
          for (const item of this.RankingData) {
            let indexLength = item.HalfYear.length + 1
            for (let i = indexLength; i <= 2; i++) {
              if (i == 2) {
                item.HalfYear.push({ Year: secondHalf,...tempNewData });
              }
          }
            if (item.HalfYear && item.HalfYear.every(subItem => subItem.Year && subItem.Year.trim() !== '')) {
              foundData = item;
              break; // Stop the loop once a valid item is found
            }
            return item.HalfYear;
          }
          if (foundData) {
            this.header.header = foundData.HalfYear.map(item => {
              return { Year: this.translate(item.Year) };
            });
          }
        }
        this.header.month=this.header.header.map(x=>{return x.Month}).flat()
        // this.checkScrollbarVisibility()
      },
      setNational(){
        this.NationalData = this.RankingData.reduce((total, item) => {
            total.SalesOfficeName = '全国平均',
            [
              'TH', 'KakouIrai', 'ALVS', 'ALVS_Total', 'Denki', 'Denki_Total', 'DenkiExpress', 'DenkiExpress_Total', 'ExternalPers', 'ExternalPers_Total',
              'House_presentation','House_presentation_Total','DecidedLandDays','DecidedLandDays_Total','FinalPlan','FinalPlan_Total',
              'StopCheck','StopCheck_Total','JoutouDays','JoutouDays_Total','SI_JIKU','SI_JIKU_Total','SI_WAKU','SI_WAKU_Total',
              'Cad','Cad_Total','CadExpress','CadExpress_Total','CadPhase1','CadPhase1_Total','CadPdr','CadPdr_Total','CadFinal','CadFinal_Total'
            ].forEach(key => total[key] = (total[key] || 0) + item[key]);

            total.data = item.data.map((x, index) => {
              total.data[index] = total.data[index] || {};
              const accumulate = (key) => (total.data[index][key] || 0) + x[key];
              const computeAvg = (key) => this.tofixed2(total.data[index][key] / total.data[index][`${key}_Total`]);

              const keysToAccumulate = [
                'TH', 'KakouIrai', 'ALVS', 'ALVS_Total', 'Denki', 'Denki_Total', 'DenkiExpress', 'DenkiExpress_Total', 'ExternalPers', 'ExternalPers_Total',
                'House_presentation','House_presentation_Total','DecidedLandDays','DecidedLandDays_Total','FinalPlan','FinalPlan_Total',
                'StopCheck','StopCheck_Total','JoutouDays','JoutouDays_Total','SI_JIKU','SI_JIKU_Total','SI_WAKU','SI_WAKU_Total',
                'Cad','Cad_Total','CadExpress','CadExpress_Total','CadPhase1','CadPhase1_Total','CadPdr','CadPdr_Total','CadFinal','CadFinal_Total'
              ];

              const newData = keysToAccumulate.reduce((acc, key) => ({ ...acc, [key]: accumulate(key) }), {
                Month: x.Month,
                Year: x.Year,
                THAvg: this.tofixed2(total.data[index].TH / total.data[index].KakouIrai),
                ALVSAvg: computeAvg('ALVS'),
                DenkiAvg: computeAvg('Denki'),
                DenkiExpressAvg: computeAvg('DenkiExpress'),
                ExternalPersAvg: computeAvg('ExternalPers'),
                House_presentationAvg: computeAvg('House_presentation'),
                DecidedLandDaysAvg: computeAvg('DecidedLandDays'),
                FinalPlanAvg: computeAvg('FinalPlan'),
                StopCheckAvg: computeAvg('StopCheck'),
                JoutouDaysAvg: computeAvg('JoutouDays'),
                SI_JIKUAvg: computeAvg('SI_JIKU'),
                SI_WAKUAvg: computeAvg('SI_WAKU'),
                CadAvg: computeAvg('Cad'),
                CadExpressAvg: computeAvg('CadExpress'),
                CadPhase1Avg: computeAvg('CadPhase1'),
                CadPdrAvg: computeAvg('CadPdr'),
                CadFinalAvg: computeAvg('CadFinal')
              });

              return newData;
            });
          total.Quarterly = [
            { Year:'FirstQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
            { Year:'SecondQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
            { Year:'ThirdQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
            { Year:'FourthQuarter',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0}
          ]
          total.HalfYear = [
            {  Year:'FirstHalf',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0},
            {  Year:'SecondHalf',KakouIrai:0,SI_JIKU_Total:0,SI_WAKU_Total:0,Cad_Total:0,CadExpress_Total:0,CadPhase1_Total: 0,CadPdr_Total: 0,CadFinal_Total: 0,Denki_Total:0,DenkiExpress_Total:0,ExternalPers_Total:0,House_presentation_Total:0,ALVS_Total:0,JoutouDays_Total:0,DecidedLandDays_Total:0,FinalPlan_Total:0,StopCheck_Total:0,TH:0,Cad:0,CadPhase1:0,CadFinal:0,CadPdr:0,Denki:0,DenkiExpress:0,ExternalPers:0,House_presentation:0,SI_JIKU:0,SI_WAKU:0,ALVS:0,JoutouDays:0,DecidedLandDays:0,FinalPlan:0,StopCheck:0}
          ]
          total.Quarterly = total.data.reduce((acc, x, index) => {
            const quarterIndex = index >= 0 && index <= 3 ? 0 : index >= 4 && index <= 6 ? 1 : index >= 7 && index <= 9 ? 2 : 3;
            const quarterLabel = ['FirstQuarter', 'SecondQuarter', 'ThirdQuarter', 'FourthQuarter'][quarterIndex];
            acc[quarterIndex] = acc[quarterIndex] || {};

            const accumulate = (key) => (acc[quarterIndex][key] || 0) + x[key];
            const computeAvg = (key) => this.tofixed2(acc[quarterIndex][key] / acc[quarterIndex][`${key}_Total`]);

            const keysToAccumulate = ['TH', 'KakouIrai', 'ALVS', 'ALVS_Total', 'Denki', 'Denki_Total', 'DenkiExpress', 'DenkiExpress_Total', 'ExternalPers', 'ExternalPers_Total',
              'House_presentation', 'House_presentation_Total', 'DecidedLandDays', 'DecidedLandDays_Total', 'FinalPlan', 'FinalPlan_Total',
              'StopCheck', 'StopCheck_Total', 'JoutouDays', 'JoutouDays_Total', 'SI_JIKU', 'SI_JIKU_Total', 'SI_WAKU', 'SI_WAKU_Total',
              'Cad', 'Cad_Total','CadExpress','CadExpress_Total', 'CadPhase1', 'CadPhase1_Total', 'CadPdr', 'CadPdr_Total', 'CadFinal', 'CadFinal_Total'];

            keysToAccumulate.forEach((key) => {
              acc[quarterIndex][key] = accumulate(key);
            });

            acc[quarterIndex] = {
              ...acc[quarterIndex],
              Year: quarterLabel, 
              THAvg: this.tofixed2(acc[quarterIndex].TH / acc[quarterIndex].KakouIrai),
              ALVSAvg: computeAvg('ALVS'),
              DenkiAvg: computeAvg('Denki'),
              DenkiExpressAvg: computeAvg('DenkiExpress'),
              ExternalPersAvg: computeAvg('ExternalPers'),
              House_presentationAvg: computeAvg('House_presentation'),
              DecidedLandDaysAvg: computeAvg('DecidedLandDays'),
              FinalPlanAvg: computeAvg('FinalPlan'),
              StopCheckAvg: computeAvg('StopCheck'),
              JoutouDaysAvg: computeAvg('JoutouDays'),
              SI_JIKUAvg: computeAvg('SI_JIKU'),
              SI_WAKUAvg: computeAvg('SI_WAKU'),
              CadAvg: computeAvg('Cad'),
              CadExpressAvg: computeAvg('CadExpress'),
              CadPhase1Avg: computeAvg('CadPhase1'),
              CadPdrAvg: computeAvg('CadPdr'),
              CadFinalAvg: computeAvg('CadFinal')
            };

            return acc;
          }, []);
          total.HalfYear = total.data.reduce((acc, x, index) => {
            const HalfYearIndex = index>=0 && index <=6 ? 0 : index >=7 && index <= 12 ? 1 :'';
            const HalfYearLabel = ['FirstHalf', 'SecondHalf'][HalfYearIndex];
            acc[HalfYearIndex] = acc[HalfYearIndex] || {};

            const accumulate = (key) => (acc[HalfYearIndex][key] || 0) + x[key]; 
            const computeAvg = (key) => this.tofixed2(acc[HalfYearIndex][key] / acc[HalfYearIndex][`${key}_Total`]);

            const keysToAccumulate = ['TH', 'KakouIrai', 'ALVS', 'ALVS_Total', 'Denki', 'Denki_Total', 'DenkiExpress', 'DenkiExpress_Total', 'ExternalPers', 'ExternalPers_Total',
              'House_presentation', 'House_presentation_Total', 'DecidedLandDays', 'DecidedLandDays_Total', 'FinalPlan', 'FinalPlan_Total',
              'StopCheck', 'StopCheck_Total', 'JoutouDays', 'JoutouDays_Total', 'SI_JIKU', 'SI_JIKU_Total', 'SI_WAKU', 'SI_WAKU_Total',
              'Cad', 'Cad_Total','CadExpress','CadExpress_Total', 'CadPhase1', 'CadPhase1_Total', 'CadPdr', 'CadPdr_Total', 'CadFinal', 'CadFinal_Total'];

            keysToAccumulate.forEach((key) => {
              acc[HalfYearIndex][key] = accumulate(key);
            });
            acc[HalfYearIndex] = {
              ...acc[HalfYearIndex],
              Year: HalfYearLabel, 
              THAvg: this.tofixed2(acc[HalfYearIndex].TH / acc[HalfYearIndex].KakouIrai),
              ALVSAvg: computeAvg('ALVS'),
              DenkiAvg: computeAvg('Denki'),
              DenkiExpressAvg: computeAvg('DenkiExpress'),
              ExternalPersAvg: computeAvg('ExternalPers'),
              House_presentationAvg: computeAvg('House_presentation'),
              DecidedLandDaysAvg: computeAvg('DecidedLandDays'),
              FinalPlanAvg: computeAvg('FinalPlan'),
              StopCheckAvg: computeAvg('StopCheck'),
              JoutouDaysAvg: computeAvg('JoutouDays'),
              SI_JIKUAvg: computeAvg('SI_JIKU'),
              SI_WAKUAvg: computeAvg('SI_WAKU'),
              CadAvg: computeAvg('Cad'),
              CadExpressAvg: computeAvg('CadExpress'),
              CadPhase1Avg: computeAvg('CadPhase1'),
              CadPdrAvg: computeAvg('CadPdr'),
              CadFinalAvg: computeAvg('CadFinal')
            };

            return acc;
          }, []);

          total.THAvg = this.tofixed2(total.TH/total.KakouIrai);
          [
            'ALVS', 'Denki', 'DenkiExpress', 'ExternalPers','House_presentation','DecidedLandDays','FinalPlan',
            'StopCheck','JoutouDays','SI_JIKU','SI_WAKU','Cad','CadExpress','CadPhase1','CadPdr','CadFinal'
          ].forEach(key => total[`${key}Avg`] = this.tofixed2(total[key] / total[`${key}_Total`]));

          return total;
        }, { 
          TH: 0, KakouIrai: 0, THAvg: 0,  
          ALVS: 0, ALVS_Total: 0, ALVSAvg: 0, 
          Denki: 0, Denki_Total: 0, DenkiAvg: 0, 
          DenkiExpress: 0, DenkiExpress_Total: 0, DenkiExpressAvg: 0, 
          ExternalPers: 0, ExternalPers_Total: 0, ExternalPersAvg: 0,
          House_presentation: 0, House_presentation_Total: 0, House_presentationAvg: 0,
          DecidedLandDays: 0, DecidedLandDays_Total: 0, DecidedLandDaysAvg: 0,
          FinalPlan: 0, FinalPlan_Total: 0, FinalPlanAvg: 0,
          StopCheck: 0, StopCheck_Total: 0, StopCheckAvg: 0,
          JoutouDays: 0, JoutouDays_Total: 0, JoutouDaysAvg: 0,
          SI_JIKU: 0, SI_JIKU_Total: 0, SI_JIKUAvg: 0,
          SI_WAKU: 0, SI_WAKU_Total: 0, SI_WAKUAvg: 0,
          Cad: 0, Cad_Total: 0, CadAvg: 0,
          CadExpress: 0, CadExpress_Total: 0, CadExpressAvg: 0,
          CadPhase1: 0, CadPhase1_Total: 0, CadPhase1Avg: 0,
          CadPdr: 0, CadPdr_Total: 0, CadPdrAvg: 0,
          CadFinal: 0, CadFinal_Total: 0, CadFinalAvg: 0,
          data: [], Quarterly: [], HalfYear: []
        });
        this.RankingData.unshift(this.NationalData);
      },
      setTempData(){
        this.RankingData.forEach((obj) => {
          this.header.header.forEach((data) => {
            data.Month && data.Month.forEach((c) => {
                  let index2 = obj.data.findIndex(x => x.Year === data.Year && x.Month === c);
                  if (index2 === -1) {
                      obj.data.push({
                          KakouIrai: 0,
                          SI_JIKU_Total: 0,
                          SI_WAKU_Total: 0,
                          Cad_Total: 0,
                          CadExpress_Total: 0,
                          CadPhase1_Total: 0,
                          CadPdr_Total: 0,
                          CadFinal_Total: 0,
                          Denki_Total: 0,
                          DenkiExpress_Total: 0,
                          ExternalPers_Total: 0,
                          House_presentation_Total: 0,
                          ALVS_Total: 0,
                          JoutouDays_Total: 0,
                          DecidedLandDays_Total: 0,
                          FinalPlan_Total: 0,
                          StopCheck_Total: 0,
                          Year: data.Year,
                          Month: c,
                          TH: 0,
                          Cad: 0,
                          CadExpress: 0,
                          CadPhase1: 0,
                          CadPdr: 0,
                          CadFinal: 0,
                          Denki: 0,
                          DenkiExpress: 0,
                          ExternalPers: 0,
                          House_presentation: 0,
                          SI_JIKU: 0,
                          SI_WAKU: 0,
                          ALVS: 0,
                          JoutouDays: 0,
                          DecidedLandDays: 0,
                          FinalPlan: 0,
                          StopCheck: 0
                      });
                  }
              });
          });
        })
      },
      sortHeaders() {
        // Sort the main array based on this.categorySelected.id
        this.RankingData = this.RankingData.sort((a, b) => {
          const idsToSortAsc = ['KakouIrai'];

          if (idsToSortAsc.includes(this.categorySelected.id)) {
            return b[this.categorySelected.id] - a[this.categorySelected.id];
          } else {
            return a[this.categorySelected.id] - b[this.categorySelected.id];
          }
        });

        const nationalAverageIndex = this.RankingData.findIndex(item => item.SalesOfficeName === '全国平均');
        if (nationalAverageIndex !== -1) {
          const nationalAverageItem = this.RankingData.splice(nationalAverageIndex, 1)[0];
          this.RankingData.unshift(nationalAverageItem);
        }

        // Sort the data array within each object based on Year and Month
        this.RankingData.forEach(item => {
          item.data = item.data.sort((a, b) => {
            if (a.Year === b.Year) {
              return parseInt(a.Month) - parseInt(b.Month);
            }
            return a.Year - b.Year;
          });
          return item.data
        });
        if (!this.sortingInProgress) {
          this.sortingInProgress = true;
          this.Header()
        }
        
        this.$nextTick(() => {
          this.plannerData.getTableHeight()
        });
    },
    getQuarterlyRanges(fromDate, toDate) {
      // Monthly date ranges
      const monthlyStartDate = new Date(fromDate);
      const monthlyEndDate = new Date(toDate);

      const monthlyDateRanges = [];

      while (monthlyStartDate <= monthlyEndDate) {
        const startOfMonth = new Date(monthlyStartDate.toISOString().slice(0, 10));
        const endOfMonth = new Date(monthlyStartDate.getFullYear(), monthlyStartDate.getMonth() + 1, 0);

        monthlyDateRanges.push({
            start: startOfMonth.toISOString().slice(0, 10),
            end: endOfMonth.toISOString().slice(0, 10),
        });

        // Adjust the start & end date of the first month
        if (monthlyDateRanges.length > 1) {
          monthlyDateRanges[monthlyDateRanges.length - 1].start = new Date(monthlyStartDate.getFullYear(), monthlyStartDate.getMonth(), 2).toISOString().slice(0, 10);
          monthlyDateRanges[monthlyDateRanges.length - 2].end = new Date(monthlyStartDate.getFullYear(), monthlyStartDate.getMonth(), +1).toISOString().slice(0, 10);
        }

        // Adjust the end date of the last month
        if (monthlyDateRanges.length -1) {
          monthlyDateRanges[monthlyDateRanges.length - 1].end = new Date(toDate).toISOString().slice(0, 10);
        }
        monthlyStartDate.setMonth(monthlyStartDate.getMonth() + 1);
      }

      // Quarterly date ranges
      const quarterlyStartDate = new Date(fromDate);
      const quarterlyEndDate = new Date(toDate);

      const quarterlyDateRanges = [];

      while (quarterlyStartDate < quarterlyEndDate) {
          const startOfQuarter = new Date(quarterlyStartDate.toISOString().slice(0, 10));
          const endOfQuarter = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth()+3, quarterlyStartDate.getDate()+2)

          quarterlyDateRanges.push({
              start: startOfQuarter.toISOString().slice(0, 10),
              end: endOfQuarter.toISOString().slice(0, 10),
          });

          // Adjust the start & end date of the first quarter
          if (quarterlyDateRanges.length > 1) {
            quarterlyDateRanges[quarterlyDateRanges.length - 1].start = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth(),quarterlyStartDate.getDate()+1).toISOString().slice(0, 10);
            quarterlyDateRanges[quarterlyDateRanges.length - 2].end = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth(), quarterlyStartDate.getDate()).toISOString().slice(0, 10);
          }

          // Adjust the end date of the last quarter
          if (quarterlyDateRanges.length -1) {
            quarterlyDateRanges[quarterlyDateRanges.length - 1].end = new Date(toDate).toISOString().slice(0, 10);
          }
          quarterlyStartDate.setMonth(quarterlyStartDate.getMonth() + 3);
      }
      while (quarterlyDateRanges.length < 4) {
        quarterlyDateRanges.push({ start: null, end: null});
      }

      // Halfyear date ranges
      const HalfyearStartDate = new Date(fromDate);
      const HalfyearEndDate = new Date(toDate);

      const HalfyearDateRanges = [];

      while (HalfyearStartDate < HalfyearEndDate) {
          const startOfHalfyear = new Date(HalfyearStartDate.toISOString().slice(0, 10));
          const endOfHalfyear = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth()+3, HalfyearStartDate.getDate()+2)

          HalfyearDateRanges.push({
              start: startOfHalfyear.toISOString().slice(0, 10),
              end: endOfHalfyear.toISOString().slice(0, 10),
          });

          // Adjust the start & end date of the first half
          if (HalfyearDateRanges.length > 1) {
            HalfyearDateRanges[HalfyearDateRanges.length - 1].start = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth(),quarterlyStartDate.getDate()+1).toISOString().slice(0, 10);
            HalfyearDateRanges[HalfyearDateRanges.length - 2].end = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth(), quarterlyStartDate.getDate()).toISOString().slice(0, 10);
          }

          // Adjust the end date of the second half
          if (HalfyearDateRanges.length -1) {
            HalfyearDateRanges[HalfyearDateRanges.length - 1].end = new Date(toDate).toISOString().slice(0, 10);
          }
          HalfyearStartDate.setMonth(HalfyearStartDate.getMonth() + 6);
      }
      while (HalfyearDateRanges.length < 2) {
        HalfyearDateRanges.push({ start: null, end: null});
      }
      this.monthly = [...monthlyDateRanges]
      this.quarterly = [...quarterlyDateRanges]
      this.halfyear = [...HalfyearDateRanges]
      
      
    },
    formatDate(dateString) {
      if(dateString){
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Adding 1 because getMonth returns zero-based months
        const day = date.getDate();

        return `${year}/${month}/${day}`;
      }else{
        return ''
      }
    },
    sortedData(item) {
      return item.sortedData
    },
    test(){
      axios.get(`http://10.169.140.17:4567/local/getCadRequestLocal/2022-01-01/2022-12-31`).then(data => {
        console.log(data.data);
      })
    },
    ExportFile(datas, category, duration, headers, gatherby){
      this.loading = true
      try {
        this.downloadFile(datas, category, duration, headers, gatherby)
      } catch (error) {
        this.loading = false
      }
    },

    async downloadFile(datas, category, duration, headers, gatherby){
      this.loading = true
      function getColumnLetter(columnNumber) {
        let dividend = columnNumber;
        let columnName = '';
        let modulo;

        while (dividend > 0) {
            modulo = (dividend - 1) % 26;
            columnName = String.fromCharCode(65 + modulo) + columnName;
            dividend = Math.floor((dividend - modulo) / 26);
        }

        return columnName;
      }
      function getData(worksheet, timePeriod, translate){
        let rank = 0
        let isNationalAverage = true
        worksheet.columns.pop()
        datas.forEach((data) => {
          const rowData = worksheet.columns.map((column) => {
              if(column.key === 'Rank'){
                if(gatherby !== 1 && isNationalAverage){
                  isNationalAverage = false
                  return '全国平均'
                }else{
                  return rank+=1
                }
              }else if(column.key !== undefined){
                return data[column.key]
              }else if(column.values.includes('合計')){
                return data[category.id]
              }else{
                if(timePeriod == 'Monthly'){
                  const [ , val1, val2 ] = column.values
                  if(val1 && val2){
                    let newVal1 = val1.substring(0, 4)
                    let newVal2 = val2.substring(0, 2)
                    let values = data.data.filter(item => item.Year === newVal1 && item.Month === newVal2).map(item => item[category.id])[0];
                    if(values == null || values == '―'){
                      return 0
                    }else{
                      return values
                    }
                  }
                }else{
                  const [ , val ] = column.values
                  if(val){
                    let values = data[timePeriod].filter(item => translate(item.Year) === val).map(item => item[category.id])[0];
                    if(values == null || values == '―'){
                      return 0
                    }else{
                      return values
                    }
                  }
                }
              }
          });
          worksheet.addRow(rowData);
        });
      }
      
      if (datas) {
        let currentDate = moment().format('YYYYMMDD')
        const worksheetLabel = gatherby === 0 ? '社員別' : '営業所別'
        const workbook = new ExcelJS.Workbook();
        const worksheet =  workbook.addWorksheet(worksheetLabel, {
          pageSetup:{paperSize: 9, orientation:'portrait', scale: 65}
        });
        worksheet.pageSetup.margins = {
          left: 0.4, right: 0.2,
          top: 0.3, bottom: 0.3,
          header: 0.1, footer: 0.1
        };

        worksheet.views = [
          {state: 'frozen', ySplit: duration === 'Monthly' ? 2 : 1}
        ];

        const dataLength = duration === 'Monthly' ? datas.length + 2 : datas.length + 1
        let columnLength = 0
        
        if(gatherby === 1){
          worksheet.columns = [
            { header: '順位', key:'Rank', width: 8 * 1.3 },
            { header: '営業所CD', key:'SalesOfficeCode', width: 9 * 1.3 },
            { header: '営業所名', key:'SalesOfficeName',  width: 16 * 1.3 },
            { header: '展示場CD', key:'BranchCode', width: 9 * 1.3 },
            { header: '展示場名', key:'BranchName', width: 19 * 1.3 },
            { header: '社員CD', key:'EmployeeCode', width: 8 * 1.3 },
            { header: '設計担当者名', key:'EmployeeName', width: 12 * 1.3 }
          ];
        }else{
          worksheet.columns = [
            { header: '順位', key:'Rank', width: 8 * 1.3 },
            { header: '営業所CD', key:'SalesOfficeCode', width: 9 * 1.3 },
            { header: '営業所名', key:'SalesOfficeName',  width: 16 * 1.3 },
          ];
        }
        if(duration === 'Monthly'){
          //Headers
          const needToMergeCells = gatherby === 1 ? ['A', 'B', 'C', 'D', 'E', 'F', 'G'] : ['A', 'B', 'C'];
          const mergeCellAddress = needToMergeCells.map(cell => `${cell}1:${cell}2`);
          mergeCellAddress.forEach(address => worksheet.mergeCells(address));

          let cellCount = worksheet.columns.length + 1
          headers.header.forEach(header => {
            let count = cellCount
            header.Month.forEach((month, index) =>{
              if(index === 0){
                worksheet.spliceColumns(count, 0, [`${header.Year}年`, `${month}月`])
                count+=1
              }else{
                worksheet.spliceColumns(count, 0, ['', `${month}月`])
                count+=1
              }
              columnLength = count
            })
            worksheet.mergeCells(`${getColumnLetter(cellCount)}1:${getColumnLetter(cellCount + header.Month.length -1)}1`)
            cellCount = cellCount + header.Month.length
          });
          worksheet.spliceColumns(cellCount, 0, ['合計'])
          worksheet.mergeCells(`${getColumnLetter(cellCount)}1:${getColumnLetter(cellCount)}2`)
          
          getData(worksheet, 'Monthly') //Data
        }else if(duration === 'Quarterly'){
          //Headers
          let cellCount = worksheet.columns.length + 1
          worksheet.spliceColumns(cellCount, 0, ['第一四半期'],['第二四半期'],['第三四半期'],['第四四半期'],['合計'])
          for (let index = cellCount; index < cellCount + 4; index++) {
            worksheet.getColumn(index).width = 11 * 1.3;
          }
          columnLength = cellCount + 4
          getData(worksheet, 'Quarterly', this.translate) //Datas
        }else if(duration === 'HalfYear'){
          //Headers
          let cellCount = worksheet.columns.length + 1
          worksheet.spliceColumns(cellCount, 0, ['上半期'],['下半期'],['合計'])
          columnLength = cellCount + 2
          getData(worksheet, 'HalfYear', this.translate) //Datas
        }else{
          //Headers
          let cellCount = worksheet.columns.length + 1
          worksheet.spliceColumns(cellCount, 0, ['合計'])
          columnLength = cellCount
          getData(worksheet, 'WholeTerm', this.translate) //Datas
        }
        let mergeCellLodation = duration == 'Monthly' ? 3 : 2
        for (let row = 1; row <= dataLength; row++) {
          for (let col = 1; col <= columnLength; col++) {
            const cell = worksheet.getCell(row, col);
            if(gatherby !== 1 && row == mergeCellLodation){
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C0C0C0' } };
            }else if(duration == 'Monthly' && row <= 2){
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '729FCF' } };
            }else if(duration != 'Monthly' && row < 2){
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '729FCF' } };
            }else{
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
              cell.border  = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
            }
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border  = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
          }
        }
        if(gatherby !== 1){
          worksheet.mergeCells(`A${mergeCellLodation}: C${mergeCellLodation}`)
        }
        const buffer = await workbook.xlsx.writeBuffer();
        
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${category.title} ${currentDate}.xlsx`;
        link.click();
        this.loading = false
      }
    },
    logout(){
      Swal.fire({
        icon: "info",
        title: `Token Expired System will forced logout`,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
        this.$store.commit("STORE_USERINFO", null);
        this.$store.commit("STORE_SALESOFFICE", null)
        this.$store.commit("STORE_CHART_URL", null);
        this.$router.push("/login");
        }
      });
    }
  },
  mounted () {
      // this.getPlannerCode()
      this.apiKey.headers.Authorization = this.userInfo.Token
      this.categorySelected = this.items.find(item => item.id === 'KakouIrai');
      this.getQuarterlyRanges(this.plannerCodeRange.fromDate, this.plannerCodeRange.toDate);
      window.addEventListener('resize', () =>{
        this.plannerData.getTableHeight()
      })
      // this.plannerData.getMonths('2023-01-01', '2023-07-01')

  },
  computed: {
    monthRestriction() {
        return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
    },
    userInfo() {
      return this.$root.decryptedUser;
    },
    sortedRankingData() {
      return this.RankingData.map(item => {
        let dataToSort = this.POTSelected === 'Monthly' ? item.data : this.POTSelected === 'Quarterly' ? item.Quarterly : item.HalfYear;
        return {
          ...item,
          sortedData: dataToSort.sort((a, b) => {
            if (a.Year === b.Year) {
              return parseInt(a.Month) - parseInt(b.Month);
            }
            return a.Year - b.Year;
          })
        };
      });
    }
  },
  // watch: {
  //   '$i18n.locale'() {
  //     if (this.RankingData.length !== 0) {
  //       this.Header();
  //     }
  //   },
  // }
}
</script>

<style lang="scss" scoped>
.sticky-thead {
  position: sticky;
  z-index: 1;
  background-color: white;
}
.TableStyle {
  border: 1px solid black;
}

th{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #01579b !important;
  color:white !important;
  text-align: center !important;
  white-space: nowrap;
  padding: 0;
}
tr{
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
td{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  text-align: center !important;
  white-space: nowrap;
  padding: 0;
}
.bg3{
  background-color:#B0BEC5!important;
}
.bg2{
  background-color:#CFD8DC!important;
}
.bg{
  background-color:#E1F5FE!important;
}
.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>