<template>
  <div>
    <v-container>
      <v-toolbar flat dense>
        <v-spacer />
        <c-date-picker  
          class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.fromDate')"
          dense outlined  hide-details="auto"
          @input="getPlannerCode()"
          v-model="plannerCodeRange.fromDate">
        </c-date-picker>

        <c-date-picker
          class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.toDate')"
          dense
          outlined
          :max="monthRestriction"
          @input="getPlannerCode()"
          v-model="plannerCodeRange.toDate"
          hide-details="auto"
        ></c-date-picker>

        <!-- <v-autocomplete
          outlined
          hide-details="auto"
          dense
          :items="SalesOfficeArr"
          :loading="SalesLoading"
          style="max-width: 25%"
          v-model="salesOffice"
          :label="$t('PlannersInfo.SalesOfficeName')"
          item-text="SalesOfficeData"
          item-value="SalesOfficeCode"
          return-object
          @change="arr.length > 0 ? getPlannerData() : ''"
        ></v-autocomplete> -->
        <v-autocomplete
          outlined
          hide-details="auto"
          dense
          :items="SalesOfficeArr"
          :loading="SalesLoading"
          style="max-width: 25%"
          v-model="salesOffice"
          :label="$t('PlannersInfo.SalesOfficeName')"
          item-text="SalesOfficeData"
          item-value="SalesOfficeCode"
          return-object
          @change="getPlannerData()"
        ></v-autocomplete>
          

        <v-autocomplete
          class="ml-2"
          outlined
          maxlength="5"
          dense
          :label="$t('plannerCodeRange.PeriodOfTime')"
          :items="periodOfTimeCategories"
          hide-details="auto"
          v-model="periodOfTime"
          :item-text="$i18n.locale === 'ja' ? 'jp' : 'en'"
          item-value="en"
          style="max-width: 20%"
          @change="setPeriodOfTime(periodOfTime)"
        ></v-autocomplete>

        <v-autocomplete
          class="ml-2"
          outlined
          maxlength="5"
          dense
          :label="$t('plannerCodeRange.Select')"
          :items="typesOfBuilding"
          hide-details="auto"
          v-model="buildingType"
          :item-text="$i18n.locale === 'ja' ? 'jp' : 'en'"
          item-value="en"
          style="max-width: 20%"
          @change="setBuildingCategory(buildingType)"
        ></v-autocomplete>

        <v-btn class="ml-2" :style="{ color: 'white' }" :disabled="loading" color="#0D47A1" @click="dialog=true">
          {{$t('plannerCodeRange.DisplayItems')}}
        </v-btn>
          
        <v-btn
          class="ml-2"
          @click="loadData()"
          :style="{ color: 'white' }"
          :disabled="loading || (!periodOfTime || !salesOffice || !buildingType || !tempPlanners.length > 0)"
          color="#0D47A1"
        >
          {{$t(`generate.generate`)}}
        </v-btn>
        <v-spacer/>
      </v-toolbar>
    </v-container>
    <div class="pr-3 pl-3" flat v-if="!loading && dataAvailable">
      <v-card flat>
        <v-simple-table id="tableRef" dense fixed-header :height="plannerData.tableHeight" :style="{'borderBottom': plannerData.scroll? '1px solid black' : ''}">
          <thead>
            <tr>
              <th
                v-for="(item, i) in filteredYearHeaders"
                :key="i"
                :rowspan="periodOfTime === 'Monthly' && i > 2 ? 1 : 2"
                :colspan="periodOfTime === 'Monthly' ? item['jp'].countOfSameYear * 2 : item['jp'].countOfSameYear" 
                style="white-space: nowrap; text-align: center;"
                class="pl-2 pr-2"
              >
                <div style="display: flex; align-items: center; justify-content: center;">
                  <span>
                    {{ i > 2
                        ? ($i18n.locale == 'ja' ? item['jp'].year : item['en'].year) 
                        : ($i18n.locale == 'ja' ? item['jp'] : item['en'])
                    }}
                  </span>
                </div>
              </th>
            </tr>
            <tr v-if="periodOfTime === 'Monthly'">
              <th 
                v-for="(item, index) in filteredMonthHeaders" 
                :key="index"
                colspan="2"
              >
                {{ $i18n.locale == 'ja' ? item['jp'].month : item['en'].month }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(planner, index) in planners" :key="index">
            <tr :style="{backgroundColor: planner.EmployeeCode !== '営業所合計' ? '#E1F5FE' : '#CFD8DC'}">
              <td
                style="border-bottom: 1px solid black; white-space: nowrap; text-align: center;"
                :rowspan="items.length + 1"
                :colspan="planner.EmployeeCode !== '営業所合計' ? 1 : 2"
              >
                {{ planner.EmployeeCode }}
              </td>
              <td
                v-if="planner.EmployeeCode !== '営業所合計'"
                style="border-bottom: 1px solid black; white-space: nowrap; text-align: center;"
                :rowspan="items.length + 1"
              >
                {{ planner.EmployeeName }}
              </td>
            </tr>
            <tr v-for="(item, i) in planner.items" :key="i" :style="{backgroundColor: planner.EmployeeCode !== '営業所合計' ? '' : '#CFD8DC'}">
              <td
                :style="{
                  borderBottom: '1px solid black',
                  backgroundColor: planner.EmployeeCode !== '営業所合計' ? '#E1F5FE' : '#CFD8DC',
                  whiteSpace: 'nowrap',
                  textAlign: 'center'
                }"
              >{{ item.title }}
              </td>
              <template v-for="(data, j) in planner.data[periodOfTime][item.title]">
                <td
                  style="min-width: 53px; max-width: 53px; white-space: nowrap; text-align: center;"
                  :style="{ color: item.title == '加工依頼数' || data.value1 == 'ー' ? '' : color(data.value1) }"
                  v-if="item.title === data.title"
                  :key="j"
                >
                  <div style="display: flex; align-items: center; justify-content: center; font-size: 13px">
                    <v-icon small :style="{ color: item.title == '加工依頼数' || data.value1 == 'ー' ? '' : color(data.value1) }">
                      {{ item.title == '加工依頼数' || data.value1 == 'ー' ? '' : icon(data.value1) }}
                    </v-icon>
                    <span>{{ data.value1 !== 'ー' ? `${data.value1}%` : data.value1 }}</span>
                  </div>
                </td>
                <td style="min-width: 53px; max-width: 53px; white-space: nowrap; text-align: center;" v-if="item.title === data.title" :key="data.id">
                  <div style="display: flex; align-items: center; justify-content: center; font-size: 13px">
                    <span>{{ data.value2 }}</span>
                  </div>
                </td>
              </template>
              <td v-if="periodOfTime === 'WholeTerm'">{{ planner.data[periodOfTime][item.title].value1 }}</td>
              <td v-if="periodOfTime === 'WholeTerm'">{{ planner.data[periodOfTime][item.title].value2 }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
    
    <v-dialog v-model="dialog" height="500px" width="500px">
      <v-card>
        <v-toolbar dark style="  background-color: #01579b;" flat dense outlined>
          <h3>{{$t('plannerCodeRange.DisplayItems')}}</h3>
        </v-toolbar>
        <v-card-text>
          <v-data-table 
            :items-per-page="19" 
            class="mt-2" 
            v-model="hold"
            :headers="header" 
            :items="items" 
            item-value="id"
            :hide-default-footer="true" 
            show-select
            dense
            outlined
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="setTitleCategory()" :disabled="hold.length == 0 ? true:false" color="primary">{{ 'Select' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <div v-if="!loading && !dataAvailable">
      <Logo />
    </div>
    <div v-else>
      <Loader :loading="loading"/>
    </div>
  </div>
</template>
<script>
import CDatePicker from "../components/CDatePicker.vue";
import moment from 'moment';
import axios from 'axios';
import Swal from "sweetalert2";
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import QuarterlyClass from '../assets/class/SalesOffice/Quarterly'
import PlannerData from '../assets/class/PlannerData/PlannerData.js'
import Logo from "../components/Logo.vue";
import Loader from "../components/Loader.vue"
export default {
  components: { CDatePicker, Logo, Loader},
  data() {
      return {
        plannerData: new PlannerData(),
        salesOfficefunction: new SalesOfficeClass(),
        plannersCodefunction: new PlannerCodeClass(),
        quarterly: new QuarterlyClass(),
        plannerCodeRange: {
          fromDate: moment().subtract(365,'days').startOf('day').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          Planner: {},
        },
        headers:[
          {en:'PlannersCode',jp:'設計担当者コード'},
          {en:'PlannersName',jp:'設計担当者名'},
          {en:'Item',jp:'項目'},
        ],
        SalesOfficeArr:[],
        SalesLoading:false,
        planners:[],
        tempPlanners: [],
        plannersLoading:false,
        datePicker: {},
        loading:false,
        salesOffice:'',
        dialog:false,
        header:[ {text:'Select All',value:'title', sortable:false}],
        items:[
            {id:1 ,title:'加工依頼数'},
            {id:2 ,title:'追加変更数'},
            {id:3 ,title:'1件あたりの追加変更数'},
            {id:4 ,title:'CAD_依頼回数平均'},
            {id:5 ,title:'即日CAD_依頼数平均'},
            // {id:6 ,title:'設計受託契約前'},
            {id:7 ,title:'提案電気図面依頼以降'},
            {id:8 ,title:'着手図面依頼以降'},
            {id:9 ,title:'電気図面_依頼回数平均'},
            {id:10 ,title:'即日電気_依頼数平均'},
            {id:11 ,title:'カラーパース_依頼回数平均'},
            {id:12 ,title:'HP_依頼回数平均'},
            {id:13 ,title:'構造事前検討（枠）_依頼回数平均'},
            {id:14 ,title:'構造事前検討（軸）_依頼回数平均'},
            {id:15 ,title:'ALVS_依頼回数平均'},
            {id:16 ,title:'土地決定～加工依頼_日数平均'},
            {id:17 ,title:'STOP～加工依頼_日数平均'},
            {id:18 ,title:'着手準備~加工依頼_日数平均'},
            {id:19 ,title:'加工依頼～上棟_日数平均'},
        ],
        selected:[],
        row:{},
        // periodOfTimeCategories:[{en:'Quarterly',jp:'四半期ごと'},{en:'Half-Year',jp:'半年ごと'}],periodOfTime:'',
        periodOfTimeCategories:[{en:'Monthly',jp:'月ごと'},{en:'HalfYear',jp:'半年ごと'},{en:'WholeTerm',jp:'全期間'}],periodOfTime:'',
        typesOfBuilding:[{en:'AllBuildingTypes',jp:'全建物タイプ'},{en:'StandardPlan',jp:'規格プラン'},{en:'NonStandardPlan',jp:'規格プラン以外'}],buildingType:'',
        Select:'',
        arr : [],
        arr2 : [],
        hold:[
            {id:1 ,title:'加工依頼数'},
            {id:2 ,title:'追加変更数'},
            {id:3 ,title:'1件あたりの追加変更数'},
            {id:4 ,title:'CAD_依頼回数平均'},
            {id:5 ,title:'即日CAD_依頼数平均'},
            // {id:6 ,title:'設計受託契約前'},
            {id:7 ,title:'提案電気図面依頼以降'},
            {id:8 ,title:'着手図面依頼以降'},
            {id:9 ,title:'電気図面_依頼回数平均'},
            {id:10 ,title:'即日電気_依頼数平均'},
            {id:11 ,title:'カラーパース_依頼回数平均'},
            {id:12 ,title:'HP_依頼回数平均'},
            {id:13 ,title:'構造事前検討（枠）_依頼回数平均'},
            {id:14 ,title:'構造事前検討（軸）_依頼回数平均'},
            {id:15 ,title:'ALVS_依頼回数平均'},
            {id:16 ,title:'土地決定～加工依頼_日数平均'},
            {id:17 ,title:'STOP～加工依頼_日数平均'},
            {id:18 ,title:'着手準備~加工依頼_日数平均'},
            {id:19 ,title:'加工依頼～上棟_日数平均'},
        ],
        Total:[],Total2:[],
        dataAvailable: false
      }
  },
  methods: {
    resetHeaders(){
      this.headers = [
        {en:'PlannersCode',jp:'設計担当者コード'},
        {en:'PlannersName',jp:'設計担当者名'},
        {en:'Item',jp:'項目'},
      ]
    },
    resetPlannerCodeRange(){
      this.plannerCodeRange = {
        fromDate: moment().subtract(365,'days').startOf('day').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        Planner: {},
      }
    },
    getPlannerCode() {
      if(this.isOneYearRange(this.plannerCodeRange.toDate, this.plannerCodeRange.fromDate)){
        this.dataAvailable = false
        this.tempPlanners = []
        this.periodOfTime = ''
        this.buildingType = ''
        if(!this.userInfo.User_Category){
          this.planners=[]
          this.plannerCodeRange.PlannerCode=this.userInfo.Emp_code
          let obj={
            PlannerCode:this.userInfo.Emp_code,
            fromDate:this.plannerCodeRange.fromDate,
            toDate:this.plannerCodeRange.toDate
          }
          this.plannersLoading=true
          this.plannersCodefunction.getplannersCode(this.aws,obj,this.apiKey).then((r)=>{
            this.planners=r.data
            let a = this.planners.findIndex(data=> data.EmployeeCode == obj.PlannerCode)
              if(a == -1){
                Swal.fire({
                  icon: "error",
                  title: "Error!",
                  text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                });
                this.planners.push({
                  EmployeesData:`${this.userInfo.Emp_code +' - '+this.userInfo.Name}`,
                  EmployeeCode:this.userInfo.Emp_code,
                  EmployeeName:this.userInfo.Name
                })
              }
            this.plannersLoading=r.loading
          }) 
        }else{
          this.SalesOfficeArr=[]
          this.planners=[]
          this.SalesLoading=true;
          this.salesOffice=''
          let obj={
            fromDate:this.plannerCodeRange.fromDate,
            toDate:this.plannerCodeRange.toDate,
            userCategory:this.userInfo.User_Category,
            SalesOfficeName:this.userInfo.User_Category
          }
          this.salesOfficefunction.getSalesOffice(obj,this.aws,this.apiKey).then((r)=>{
              this.SalesOfficeArr = r.data.filter((x) => x.EmployeeName !== null).sort((a, b) => a.sort - b.sort);
              this.SalesLoading = r.data.loading;
          })
        }
      }else{
        Swal.fire({
          icon: "warning",
          title: `${this.$i18n.locale =='ja' ? 'エラー' : 'Warning' }`,
          text: `${
            this.$i18n.locale == 'ja'
            ? '開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' 
            :'Please re-enter so that the period from the start date to the end date is within one year.!'
          }`,
        });
        this.resetPlannerCodeRange()
      }
    },
    async getPlannerData(){
      this.dataAvailable = false
      this.planners = []
      this.tempPlanners = []
      this.planners = this.getPlanners()
      let data = {
        fromDate: this.plannerCodeRange.fromDate,
        toDate: this.plannerCodeRange.toDate,
        planners: this.planners
      }
      let result1 = await axios.post(`${this.aws}thRanking2`, data, this.apiKey)
      let result2 = await axios.post(`${this.aws}getAllCad`, data, this.apiKey)
      let filteredData1 = result1.data
      let filteredData2 = result2.data

      let combinedData = filteredData1.map(item1 => {
        const matchingItem = filteredData2.find(item2 => 
          item2.ConstructionCode === item1.ConstructionCode
        );
        return matchingItem ? { ...item1, ...matchingItem } : item1;
      });

      let remainingFromData2 = filteredData2.filter(item2 => 
        !filteredData1.some(item1 => item1.ConstructionCode === item2.ConstructionCode)
      );

      let finalResult = [...combinedData, ...remainingFromData2];

      let groupedData = finalResult.reduce((acc, item) => {
        if (!acc[item.EmployeeCode]) {
          acc[item.EmployeeCode] = [];
        }
        acc[item.EmployeeCode].push(item);
        return acc;
      }, {});

      this.tempPlanners = this.planners.map(planner => ({
          ...planner,
          items: [...this.hold],
          data: groupedData[planner.EmployeeCode] || []
      }));
    },
    getPlanners(){
      return this.SalesOfficeArr.map((item) => {
        if(item.SalesOfficeCode === this.salesOffice.SalesOfficeCode){
          return { 
            EmployeeCode: item.EmployeeCode,
            EmployeeName: item.EmployeeName,
            data: []
          };
        }
      }).filter(item => item !== undefined)
    },
    setPeriodOfTime(data){
      this.periodOfTime = data
      this.setHeaders()
      if(this.dataAvailable){
        this.loadData()
      }
    },
    setHeaders(){
      if(this.periodOfTime === 'Monthly'){
        this.resetHeaders()
        this.getMonthlyHeaders()
      }else if(this.periodOfTime === 'HalfYear'){
        this.resetHeaders()
        this.getHalfYearlyHeaders()
      }else{
        this.resetHeaders()
        this.getWholeTermHeaders()
      }
    },
    setBuildingCategory(data){
      this.buildingType = data
      if(this.dataAvailable){
        this.dataAvailable = false
        this.loadData()
      }
    },
    setTitleCategory(){
      this.planners.forEach(item => {
        item.items = [...this.hold]
      })
      if(this.dataAvailable){
        this.dataAvailable = false
        this.loadData()
      }
      this.dialog = false
    },
    getWholeTermHeaders(){
      this.headers.push({
        en: { year: `Whole Term`, countOfSameYear: 2 },
        jp: { year: `全期間`, countOfSameYear: 2 }
      });
    },
    getHalfYearlyHeaders(){
      const startDate = new Date(this.plannerCodeRange.fromDate);
      const endDate = new Date(this.plannerCodeRange.toDate);

      let currentDate = new Date(startDate);
      let isFirstPeriod = true;

      while (currentDate <= endDate) {
          // Calculate the end date for this period
          let periodEndDate = new Date(currentDate);
          // First period is 7 months, subsequent periods are 6 months
          const monthsToAdd = isFirstPeriod ? 6 : 5; // Add 6 for 7 months total, 5 for 6 months total
          periodEndDate.setMonth(currentDate.getMonth() + monthsToAdd);

          // If period end date exceeds the overall end date, use the overall end date
          if (periodEndDate > endDate) {
              periodEndDate = endDate;
          }

          // Format start date
          const startYear = currentDate.getFullYear();
          const startMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
          
          // Format end date
          const endYear = periodEndDate.getFullYear();
          const endMonth = String(periodEndDate.getMonth() + 1).padStart(2, '0');

          this.headers.push({
              en: { year: `${startYear}/${startMonth} ~ ${endYear}/${endMonth}`, countOfSameYear: 2 },
              jp: { year: `${startYear}年/${startMonth}月 ~ ${endYear}年/${endMonth}月`, countOfSameYear: 2 }
          });

          // Move to start of next period
          currentDate = new Date(periodEndDate);
          currentDate.setMonth(currentDate.getMonth() + 1);
          isFirstPeriod = false;
      }
    },
    getMonthlyHeaders() {
      const startDate = new Date(this.plannerCodeRange.fromDate);
      const endDate = new Date(this.plannerCodeRange.toDate);

      let currentDate = new Date(startDate);
      let yearCounts = {};
      while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        yearCounts[year] = (yearCounts[year] || 0) + 1;
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
      }

      currentDate = new Date(startDate);
      let tempYear = '';
      let monthCounter = 0;

      while (currentDate <= endDate) {
        const currentYear = currentDate.getFullYear();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');

        if (tempYear !== currentYear) {
          monthCounter = 0;
          tempYear = currentYear;
        }

        monthCounter++;

        this.headers.push({
          en: { year: monthCounter === 1 ? `${currentYear}` : '', month: currentMonth, countOfSameYear: monthCounter === 1 ? yearCounts[currentYear] : 1 },
          jp: { year: monthCounter === 1 ? `${currentYear}年` : '', month: `${currentMonth}月`, countOfSameYear: monthCounter === 1 ? yearCounts[currentYear] : 1 }
        });

        currentDate = new Date(currentYear, currentDate.getMonth() + 1, 1);
      }
    },
    loadData() {
      this.loading = true;
      requestAnimationFrame(() => {
        const hasTotalSalesOffice = this.tempPlanners.some(data => data.EmployeeCode === '営業所合計');
        
        if (!hasTotalSalesOffice) {
          const totalSalesOffice = {
            EmployeeCode: '営業所合計',
            EmployeeName: null,
            data: []
          };
          totalSalesOffice.data = this.tempPlanners.map(item => item.data).flat();
          this.tempPlanners.push(totalSalesOffice);
        }
        
        this.tempPlanners.forEach(item => {
          item.items = [...this.hold];
        });

        this.getAllData(this.tempPlanners);

        requestAnimationFrame(() => {
          this.loading = false;
          this.dataAvailable = true;
          
          this.$nextTick(() => {
            this.plannerData.getTableHeight();
            this.plannerData.scrollExist();
          });
        });
      });
    },
    getAllData(tempPlanners) {
      let filteredPlannersData;
      let StandardPlans = [
        'i-smile(旧)',
        'i-smile(2×6)',
        'i-smileⅡ',
        '新i-smile(建具変更)⇒当面発表無し',
        '新i-smile＋(建具変更)⇒当面発表無し',
        'i-smile＋',
        'i-smileⅡ＋',
        'HUGme(ハグミー)',
        'HUGme fam(ハグミー･ファム)'
      ];
      
      if (this.buildingType === 'StandardPlan') {
        filteredPlannersData = tempPlanners.map(planner => ({
          ...planner,
          data: planner.data.filter(item => StandardPlans.includes(item.ConstructionTypeName)),
          items: [...planner.items]
        }));
      } else if (this.buildingType === 'NonStandardPlan') {
        filteredPlannersData = tempPlanners.map(planner => ({
          ...planner,
          data: planner.data.filter(item => !StandardPlans.includes(item.ConstructionTypeName)),
          items: [...planner.items]
        }));
      } else {
        filteredPlannersData = tempPlanners;
      }
      const titleFunctionMap = {
        '加工依頼数': this.getData,
        '追加変更数': this.getData,
        '1件あたりの追加変更数': this.getData,
        'CAD_依頼回数平均': this.getData,
        '即日CAD_依頼数平均': this.getData,
        '提案電気図面依頼以降': this.getData,
        '着手図面依頼以降': this.getData,
        '電気図面_依頼回数平均': this.getData,
        '即日電気_依頼数平均': this.getData,
        'カラーパース_依頼回数平均': this.getData,
        'HP_依頼回数平均': this.getData,
        '構造事前検討（枠）_依頼回数平均': this.getData,
        '構造事前検討（軸）_依頼回数平均': this.getData,
        'ALVS_依頼回数平均': this.getData,
        '土地決定～加工依頼_日数平均': this.getData,
        'STOP～加工依頼_日数平均': this.getData,
        '着手準備~加工依頼_日数平均': this.getData,
        '加工依頼～上棟_日数平均': this.getData,
      };
      filteredPlannersData.forEach(planner => {
        planner.data.Monthly = {}
        planner.data.HalfYear = {}
        planner.data.WholeTerm = {}
        const hasKakou = planner.items.some(data => data.title === '加工依頼数')

        if(!hasKakou){
          planner.items.unshift({id:1 ,title:'加工依頼数'})
        }
        planner.items.forEach(data => {
          const func = titleFunctionMap[data.title];
          if (func) {
            func.call(this, planner.data, data.title);
          }
        });
      });
      this.planners = filteredPlannersData
      this.getAverageData(this.planners)
    },
    getData(plannerData, title) {
      const startDate = new Date(this.plannerCodeRange.fromDate);
      const endDate = new Date(this.plannerCodeRange.toDate);

      this.getMonthlyData(plannerData, title, startDate, endDate)
      this.getHalfYearlyData(plannerData, title, startDate, endDate)
      this.getWholeTermData(plannerData, title)
    },
    getAverageData(plannerData){
      plannerData.forEach(planner => {
        planner.items.forEach(item => {
          if(this.periodOfTime === 'WholeTerm'){
            const totalKakou = {
              key: planner.data[this.periodOfTime]['加工依頼数'].range,
              val: planner.data[this.periodOfTime]['加工依頼数'].value2
            };
            if(item.title != '加工依頼数' && item.title != '追加変更数'){
              this.processWholeTermAverageData(item.title, totalKakou, planner);
            }
          }else{
            const totalKakou = planner.data[this.periodOfTime]['加工依頼数'].map(data => ({
              key: data.range,
              val: data.value2
            }));
            totalKakou.forEach((kakou, index) => {
              if(item.title != '加工依頼数' && item.title != '追加変更数'){
                this.processAverageData(item.title, kakou, planner, index);
              }
            })
          }
        })
      })
      plannerData.forEach(planner => {
        const hasKakouData = planner.items.some(data => data.title === '加工依頼数')
        const hasKakouHolder = this.hold.some(data => data.title === '加工依頼数')
        if(hasKakouData && !hasKakouHolder){
          planner.data[this.periodOfTime] = Object.fromEntries(
            Object.entries(planner.data[this.periodOfTime]).filter(([key]) => key !== '加工依頼数')
          );
          planner.items = planner.items.filter(data => data.title !== '加工依頼数');
        }
      })
    },
    processWholeTermAverageData(title, kakou, planner){
      const data = planner.data[this.periodOfTime][title]
      if (kakou.key === data.range) {
        let average = kakou.val !== 0 ? data.value2 / kakou.val : 0;
        planner.data[this.periodOfTime][title].value2 = this.tofixed2(average);
      }
    },
    processAverageData(title, kakou, planner, index) {
      let previousValue2Month = null;
      planner.data[this.periodOfTime][title].forEach(data => {
        if (kakou.key === data.range) {
          let average = kakou.val !== 0 ? data.value2 / kakou.val : 0;
          data.value2 = this.tofixed2(average);

          if (index === 0) {
            data.value1 = 'ー';
          } else {
            let currentValue2 = data.value2;
            if (previousValue2Month !== null && previousValue2Month !== 0) {
              data.value1 = this.getAverage(currentValue2, previousValue2Month);
            }else{
              data.value1 = 0
            }
          }
        }
        previousValue2Month = data.value2;
      });
    },
    processData(obj, title, data){
      if (title === '加工依頼数') { obj.value2++ }
      if (title === '追加変更数') { obj.value2 += (data.TH || 0) }
      if (title === '1件あたりの追加変更数') { obj.value2 += (data.TH || 0) }
      if (title === 'CAD_依頼回数平均') { obj.value2 += (data.cad || 0) }
      if (title === '即日CAD_依頼数平均') { obj.value2 += (data.CadExpress || 0) }
      if (title === '提案電気図面依頼以降') { obj.value2 += data.CadPdr > 0 ? data.CadPdr : (data.CadDenkiPlan || 0) }
      if (title === '着手図面依頼以降') { obj.value2 += (data.CadFinalPlan || 0) }
      if (title === '電気図面_依頼回数平均') { obj.value2 += (data.denki || 0) }
      if (title === '即日電気_依頼数平均') { obj.value2 += (data.denkiExpress || 0) }
      if (title === 'カラーパース_依頼回数平均') { obj.value2 += (data.ExternalPers || 0) }
      if (title === 'HP_依頼回数平均') { obj.value2 += (data.House_presentation || 0) }
      if (title === '構造事前検討（枠）_依頼回数平均') { obj.value2 += (data.SI_WAKU_DUCT || 0) }
      if (title === '構造事前検討（軸）_依頼回数平均') { obj.value2 += (data.SI_JIKU || 0) }
      if (title === 'ALVS_依頼回数平均') { obj.value2 += (data.ALVS || 0) }
      if (title === '土地決定～加工依頼_日数平均') { obj.value2 += (data.decidedlandDays || 0) }
      if (title === 'STOP～加工依頼_日数平均') { obj.value2 += (data.stopcheck || 0) }
      if (title === '着手準備~加工依頼_日数平均') { obj.value2 += (data.finalPlan || 0) }
      if (title === '加工依頼～上棟_日数平均') { obj.value2 += (data.JoutouDays || 0) }
    },
    getMonthlyData(plannerData, title, startDate, endDate) {

      const subsequentData = [];
      const current = moment(startDate).startOf('month');
      let isFirstEntry = true;
      let previousValue2Month = null;
      
      while (current.isSameOrBefore(endDate)) {

        const year = current.format('YYYY');
        const month = current.format('MM');

        subsequentData.push({
          range: `${year}/${month}`,
          value1: isFirstEntry ? 'ー' : 0,
          value2: 0,
          title: title
        });

        isFirstEntry = false;
        current.add(1, 'month');
      }
      plannerData.forEach(data => {
        if (data.SentProcessRequestDate) {

          const requestDate = moment(data.SentProcessRequestDate);

          if (requestDate.isBetween(startDate, endDate, 'day', '[]')) {

            const year = requestDate.format('YYYY');
            const month = requestDate.format('MM');
            const monthEntry = subsequentData.find(obj => obj.range === `${year}/${month}`);
            if (monthEntry) {
              this.processData(monthEntry, title, data)
            }
          }
        }
      });
      plannerData.Monthly[title] = [...subsequentData];
      plannerData.Monthly[title].forEach(data => {
        const monthIndex = subsequentData.findIndex(obj => obj.range === data.range);
        if (monthIndex === 0) {
          data.value1 = 'ー';
        } else {
          let currentValue2 = data.value2;
          if (previousValue2Month !== null && previousValue2Month !== 0) {
            data.value1 = this.getAverage(currentValue2, previousValue2Month);
          }else{
            data.value1 = 0
          }
        }
        previousValue2Month = data.value2
      })
    },

    getHalfYearlyData(plannerData, title, startDate, endDate){

      let subsequentData = [];
      const current = moment(startDate).startOf('month');
      let isFirstEntry = true;
      let previousValue2HalfYear = null;
      let count = 0;

      while (current.isSameOrBefore(endDate)) {
        const year = current.format('YYYY');
        const month = current.format('MM');
        
        let monthsToAdd = count === 0 ? 6 : 5;
        
        let endRangeDate = current.clone().add(monthsToAdd, 'months').endOf('month');
        const endYear = endRangeDate.format('YYYY');
        const endMonth = endRangeDate.format('MM');
        
        let range = `${year}/${month} ~ ${endYear}/${endMonth}`;
        
        subsequentData.push({
          range: range,
          value1: isFirstEntry ? 'ー' : 0,
          value2: 0,
          title: title
        });
        isFirstEntry = false;
        current.add(monthsToAdd + 1, 'months');
        count++;
        
        if (count >= 2) break;
      }
      plannerData.forEach(data => {
        if (data.SentProcessRequestDate) {
          const requestDate = moment(data.SentProcessRequestDate);
          if (requestDate.isBetween(startDate, endDate, 'day', '[]')) {
            const halfYearEntry = subsequentData.find(obj => {
              const rangeParts = obj.range.split(' ~ ');
              const startYearMonth = rangeParts[0].split('/');
              const endYearMonth = rangeParts[1].split('/');

              return ((
                requestDate.isSameOrAfter(moment(`${startYearMonth[0]}/${startYearMonth[1]}`, 'YYYY-MM').startOf('month')) &&
                requestDate.isSameOrBefore(moment(`${endYearMonth[0]}/${endYearMonth[1]}`, 'YYYY-MM').endOf('month'))
              ));
            });
            if (halfYearEntry) {
              this.processData(halfYearEntry, title, data)
            }
          }
        }
      });
      plannerData.HalfYear[title] = [...subsequentData];
      plannerData.HalfYear[title].forEach(data => {
        const halfYearIndex = subsequentData.findIndex(obj => obj.range === data.range);
        if (halfYearIndex === 0) {
          data.value1 = 'ー';
        } else {
          let currentValue2 = data.value2;
          if (previousValue2HalfYear !== null && previousValue2HalfYear !== 0) {
            data.value1 = this.getAverage(currentValue2, previousValue2HalfYear);
          }else{
            data.value1 = 0
          }
        }
        previousValue2HalfYear = data.value2
      })
    },
    getWholeTermData(plannerData, title){
      
      let subsequentData = {
        range: 'wholeTerm',
        value1: 'ー',
        value2: 0,
        title: title,
      };

      plannerData.forEach(data => {
        if (data.SentProcessRequestDate) {
          this.processData(subsequentData, title, data)
        }
      });
      plannerData.WholeTerm[title] = subsequentData
    },
    MomentJP(val){
      return moment(val).format('YYYY年/M月');
    },
    tofixed2(num){
      return (Math.round((num ) * 10) / 10).toFixed(1)
    },
    isOneYearRange(toDate, fromDate){
      return moment.duration(moment(toDate,"YYYY-MM-DD").diff(moment(fromDate,"YYYY-MM-DD"))).asDays() <= 365
    },
    getAverage(val1,val2){
      if(val2 !== 0){
        let x =Math.ceil(((val1/val2)-1)*100)
        if(x ==Infinity || isNaN(x) || x==isNaN ||  x== isFinite){
          return 0
        }else{
          return x
        }
      }else{
        return 0
      }
    },
    color(val){
      return val < 0 ? 'blue': val > 0  ? 'red' : '' 
    },
    icon(val){
      return val < 0 ? 'mdi-arrow-bottom-right' : val > 0  ?'mdi-arrow-top-right':'mdi-arrow-right' 
    },
  },
  mounted () {
    this.apiKey.headers.Authorization = this.userInfo.Token
    this.getPlannerCode()
    window.addEventListener('resize', () =>{
      this.plannerData.getTableHeight()
      this.plannerData.scrollExist();
    })
  },
  computed: {
    monthRestriction() {
      return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
    },
    userInfo() {
      return this.$root.decryptedUser;
    },
    filteredMonthHeaders() {
      return this.headers.slice(3);
    },
    filteredYearHeaders() {
      return this.headers.filter((item, index) => {
        if (index <= 2) return true;
        return item['jp'].year !== '' && item['en'].year !== '';
      });
    }
  },
}
</script>

<style lang="scss" scoped>
th{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #01579b !important;
  color:white !important;
  text-align: center !important;
  padding: 0px !important;  
}
tr{
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
td{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  text-align: center !important;

}
.bg3{
  background-color:#B0BEC5!important;
}
.bg2{
  background-color:#CFD8DC!important;
}
.bg{
  background-color:#E1F5FE!important;
}
.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>