<template>
  <v-app >
    <v-app-bar dense app color="#01579b" dark v-if="$store.state.userInfo" ref="appBar">
      <v-app-bar-nav-icon   @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-spacer />
      <v-divider vertical/>
<!-- =============================================================================================== -->
      <v-divider vertical/> 
      <div class="text-center">
        <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" text medium v-on="on" color="#01579b" plain>
              <img :src="imageSource" style="width: 34px;">
              <span class="white--text ml-2">{{ `${$i18n.locale}`}}</span>
            </v-btn>
          </template>
          <v-list style="background-color: #DCDCDC">
            <v-list-item @click="translate('ja')">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex align-center">
                    <img src="../public/img/flags/japan_64.png" style="width: 34px;">
                    <div class="ml-2">
                      <span class="d-block text-center">JA</span>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="translate('en')">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex align-center">
                    <img src="../public/img/flags/us_64.png" style="width: 34px;">
                    <div class="ml-2">
                      <span class="d-block text-center">EN</span>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider vertical/> 
<!-- =============================================================================================== -->
<!-- =============================================================================================== -->
      <v-divider vertical/> 
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs"  text medium v-on="on" color="#01579b" plain>
            <v-icon color="white" size="34">
            mdi-account
            </v-icon>
              <h5 v-if="userInfo.full_name" style="color:white" class="ml-2">{{`${userInfo.full_name}`}}</h5>
              <h5 v-else-if="userInfo.name" style="color:white" class="ml-2">{{`${userInfo.name}`}}</h5>
              <h5 v-else style="color:white" class="ml-2">{{`${userInfo.Name}`}}</h5>
            </v-btn>
          </template>
          <v-list style="background-color: #DCDCDC">
            <!-- <v-list-item @click="updatePasswordDialog = true">
              <v-icon>mdi-lock</v-icon>
              <span class="ml-2">Change Password</span>
            </v-list-item> -->
            <v-list-item @click="logout()">
              <v-icon>mdi-logout</v-icon>
              <span class="ml-2">{{$t('Logout.logout')}}</span>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-model="updatePasswordDialog" max-width="400px" persistent>
          <v-card outlined>
            <v-toolbar flat height="48" style="background-color:#CFD8DC">
              <h3 class="ml-2" style="text-wrap: nowrap;">{{ `Change Password` }}</h3>
              <v-spacer></v-spacer>
              <v-btn v-if="showElements" icon size="30"><v-icon color="red" @click="closeDialog()">mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card flat class="pa-2" style="overflow-y:auto;">
              <v-row no-gutters>
                <v-col>
                  <v-card class="ma-2" color="#E3F2FD">
                    <v-card-text class="text--primary">
                      <div class="text-center">
                        <v-text-field 
                          v-if="!userInfo.Default"
                          v-model="update.oldPassword" 
                          label="Old Password"  
                          prepend-inner-icon="mdi-lock"
                          :append-icon="showOldPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showOldPassword == true ? 'text' : 'password'"
                          @click:append="showOldPassword = !showOldPassword"
                          tabindex="1"
                        ></v-text-field>
                        <v-text-field 
                          v-model="update.newPassword" 
                          label="New Password"
                          prepend-inner-icon="mdi-lock"
                          :append-icon="showNewPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showNewPassword == true ? 'text' : 'password'"
                          @click:append="showNewPassword = !showNewPassword"
                          tabindex="2"
                        ></v-text-field>
                        <v-text-field 
                          ref="password"
                          v-model="update.confirmPassword" 
                          label="Confirm Password" 
                          :rules="[validatePasswordMatch]"
                          prepend-inner-icon="mdi-lock"
                          :append-icon="showConfirmPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showConfirmPassword == true ? 'text' : 'password'"
                          @click:append="showConfirmPassword = !showConfirmPassword"
                          @keyup.enter="updateSubmit(update)"
                          tabindex="3"
                        ></v-text-field>
                        <div class="text-right mt-4">
                          <v-btn outlined color="primary" 
                            :disabled="showElements && !update.oldPassword || !update.newPassword || !update.confirmPassword || enabledButton != true" 
                            @click="updateSubmit(update)"
                            tabindex="4"
                          >Update</v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-dialog>
      </div>
    </v-app-bar>
<!-- ===============================================================================================#1976D2 -->
    <v-navigation-drawer  fixed    app  v-model="drawer" v-if="$store.state.userInfo" :width="270" style="background-color:#E3F2FD" v-click-outside="onClickOutside">
      <v-list class="py-0" dense >
        <v-list-item  style="background-color:#01579b" >
          <v-list-item-content  class="white--text" >
            <v-list-item-title class="text-center pt-3">
              <h3 style="font-weight:normal"> Ichijo Sekkei Portal Ver.<sup class="caption">{{versionLogs.latestVersion}}</sup></h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="text('Home')" to='/' >
          <v-list-item-icon >
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-tooltip right  color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon >
                  <v-icon >mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title v-bind="attrs" v-on="on" style="font-size: 14px;">Planner Data</v-list-item-title>
              </template>
              <!-- <span>{{'設計個人別データ'}}</span> -->
              <span>{{'Planner Data'}}</span>
            </v-tooltip>
          </template>
          <v-list-item :disabled="maintenanceDialog" dense class="ml-4" v-for="(item, i) in subCategory" :key="i" :to="item.to" link @click="drawer=!drawer">
            <v-tooltip right  color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="text(item.text)">
                  <v-list-item-title v-bind="attrs" v-on="on">{{item.text}}</v-list-item-title>
                </v-list-item-content> 
              </template>
              <span>{{ item.text2 }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-group>
        <v-list-item to='/linkHRD'>
          <v-tooltip right  color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon >
                <v-icon>mdi-file-export</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-bind="attrs" v-on="on">HRD Schedule</v-list-item-title>
            </template>
            <span>{{'HRD Schedule' }}</span>
          </v-tooltip>
        </v-list-item>

        <v-list-item to='/linkCAD'>
          <v-tooltip right  color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon >
                <v-icon>mdi-file-sign</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-bind="attrs" v-on="on">CAD Request System</v-list-item-title>
            </template>
            <span>{{'CAD Request System' }}</span>
          </v-tooltip>
        </v-list-item>

        <v-list-item to='/linkHRDInquiry'>
          <v-tooltip right  color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon >
                <v-icon>mdi-warehouse</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-bind="attrs" v-on="on">HRD Inquiry System</v-list-item-title>
            </template>
            <span>{{'HRD Inquiry System' }}</span>
          </v-tooltip>
        </v-list-item>
        
        <v-list-item @click="text('RuleBook')" to='/linkDSS'>
          <v-tooltip right  color="primary">
            <template v-slot:activator="{ on, attrs }">
          <v-list-item-icon >
            <v-icon>mdi-text-box-search</v-icon>
          </v-list-item-icon>
            <v-list-item-title v-bind="attrs" v-on="on">Document Search System</v-list-item-title>
          </template>
            <span>{{'Document Search System' }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item @click="getUserManual()">
          <v-tooltip right  color="primary">
            <template v-slot:activator="{ on, attrs }">
          <v-list-item-icon >
            <v-icon>mdi-book-information-variant</v-icon>
          </v-list-item-icon>
            <v-list-item-title v-bind="attrs" v-on="on">User Manual</v-list-item-title>
          </template>
            <span>{{'User Manual' }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-dialog id="maintenance" v-model="systemNotice" max-width="540px" persistent>
      <v-card>
        <v-toolbar flat height="48" style="background-color:#CFD8DC">
          <h3 class="ml-2" style="text-wrap: nowrap;"><v-icon color="red" class="pr-3">mdi-alert</v-icon>{{ `System Notice!` }}</h3>
        </v-toolbar>
        <v-card-title>
          このサービスはー時的にご利用いただけません。<br>
         
          This service is temporarily not available.<br>
         
        </v-card-title> 
      </v-card>
    </v-dialog>
    <!-- 通常のサービスは今日の午後に回復される予定です。 <br><br> -->
    <!-- Normal service will be restored this afternoon. -->
    <v-main>
        <transition :duration="500" :delay="200" name="fade" mode="out-in">
          <router-view />
        </transition>
    </v-main>
  </v-app>
</template>

<script>
import DataTransform from './assets/class/DataTransform.js'
import Swal from "sweetalert2";
import axios from 'axios'
import VersionLogs from './assets/class/Updates/VersionLogs.js'
import UserManual from './assets/class/UserManual.js'
import md5 from 'md5'
export default {
  name: "App",
  data: () => ({
    dataTransform: new DataTransform(),
    userManual: new UserManual(),
    showElements: true,
    enabledButton: false,
    showOldPassword:'',
    showNewPassword:'',
    showConfirmPassword:'',
    update:{},
    updatePasswordDialog: false,
    maintenanceDialog: false,
    systemNotice: false,
    versionLogs: new VersionLogs(),
    latestVersion: '',
    lang:'',
    logs:false,
    list:[ 
      {text:`Home`,icon:'mdi-home',to:'/'},
      {text:'PlannersData',icon:'mdi-account-circle-outline',},
      {text:'CadRequest',icon:'mdi-account-circle-outline',to:'/cadrequest'},
      {text:'GC',icon:'mdi-account-circle-outline',to:'/gcpage'},
      {text:'RuleBook',icon:'mdi-text-box-search',to:'/about'},
    ], 
    subCategory:[
        {text:"追加変更数",text2:'TSUIKA HENKOU DATA',icon:'mdi-file-document',to:'/tsuikahenkou'},
        {text:'CAD依頼数',text2:'CAD REQUEST DATA',icon:'mdi-file-document',to:'/cadrequest'},
        {text:"各種日数",text2:"DAY'S DATA",icon:'mdi-file-document',to:'/daysdata'},
        {text:"全体統計データ",text2:'OVERALL STATISTICAL DATA',icon:'mdi-file-document',to:'/overalldata'},  
        {text:"営業所別データ",text2:"DATA  FOR EACH SALE'S OFFICE",icon:'mdi-file-document',to:'/salesoffice'},  
    ],
    drawer:false,
    items: [],
    title:'',
    image: 'japan_64',
  }),
  methods: {
    getUserManual() {
      let obj={
        AccessKey :this.AccessKey,
        SecretKey :this.SecretKey,
      }
      let fileName = this.$i18n.locale === 'ja' ? 'UserManualJP.pdf' : 'UserManualEN.pdf'
      return  this.userManual.getUserManual(obj,'Files', fileName)
    },
    isDefault(){
      if (this.userInfo?.Default) { 
        this.updatePasswordDialog = true;
        this.showElements = false;
      }
    },
    closeDialog(){
      this.updatePasswordDialog = false
      this.update = {}
      if(this.$refs.password) {
        this.$refs.password.reset()
      }
    }, 
    validatePasswordMatch(value) {
      if(value){
        if (value === this.update.newPassword) {
          
          if(this.update.newPassword === 'ichijo' || this.update.newPassword === 'password'){
            this.enabledButton = false
            return 'Password does not recommended';
          }else{
            this.enabledButton = true
            return true;
          }
        } else {
          this.enabledButton = false
          return 'Password does not match';
        }
      }else{
        this.enabledButton = false
        return false
      }
    },
    updateSubmit(obj) {
      if (obj) {
        let userData = {
          oldPassword: obj.oldPassword ? md5(obj.oldPassword) : md5('ichijo'),
          confirmPassword: md5(obj.confirmPassword),
          Mail_Address: this.userInfo.Mail_Address || this.userInfo.MailAddress
        }
        // console.log(userData);
        axios.post(`${this.aws}/updatePlannerCredentials`, userData, this.apiKey)
          .then(response => {
            if (response.data === 'Old password does not match.') {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: `Old password is incorrect`,
                showConfirmButton: false,
                timer: 2200,
              });
            }else{
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: `Password Changed Successfully`,
                showConfirmButton: false,
                timer: 2200,
              });
              this.updatePasswordDialog = false;
              if (this.$refs.password) {
                this.$refs.password.reset();
                this.update = {}
              }
              this.$store.commit("STORE_USERINFO", null);
              this.$store.commit("STORE_SALESOFFICE", null)
              this.$router.push("/login");
          }
          }).catch((error) => {
            if(error.response.data === 'Old password does not match.'){
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: `古いパスワードが間違っています。`,
                    showConfirmButton: false,
                    timer: 2200,
                    width: 400,
                });
            }
            if(error.response.status == 403 || error.response.status == 401){
              Swal.fire({
                icon: "info",
                title: `Token Expired System will forced logout`,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$store.commit("STORE_USERINFO", null);
                  this.$store.commit("STORE_SALESOFFICE", null)
                  this.$store.commit("STORE_CHART_URL", null);
                  this.$router.push("/login");
                }
              });
            }
            this.loading = false;
        });
      }
    }, 
    onClickOutside(event) {
      const clickedElement = event.target;
      if (this.$refs.appBar) {
        const isInsideAppBar = this.$refs.appBar.$el.contains(clickedElement);
        if (!isInsideAppBar) {
          this.drawer = false;
        }
      }
    },
    translate(val){
      this.lang=val
      this.$i18n.locale = val
      this.$nextTick(() => {
        if(val == 'ja'){
          this.image = 'japan_64'
        }else if(val == 'en'){
          this.image = 'us_64'
        }
      })
    },
    test(item){
      if(item.title=='Logout'){
        this.logout()
      }
    },
    closeLogDialog(){
      this.logs=false
    },
    text(item){
      if(item=='RuleBook'){
        this.title="Home"
      }
      else{
        this.title=item
      }
    },
    logout() {
      Swal
        .fire({
          title: `${this.$i18n.locale =='ja'?'このアカウントからログアウトしますか？' :'Do you want to log out of this account?'}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `${this.$i18n.locale =='ja'?'ログアウト' :'Logout'}`,
          cancelButtonText:`${this.$i18n.locale =='ja'?'キャンセル' :'Cancel'}`
        })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: `${this.$i18n.locale =='ja'?'アカウントは正常にログアウトされました' :'Account logged out successfully'}`,
              showConfirmButton: false,
              timer: 2200,
            });
            this.$store.commit("STORE_USERINFO", null);
            this.$store.commit("STORE_SALESOFFICE", null)
            this.$router.push("/login");
          }
        });
    },
    async loadVersion() {
      this.apiKey.headers.Authorization = this.userInfo.Token
      await this.versionLogs.getLogs(this.aws,this.apiKey,this.deployment)
    },
    handleKeys(){
      const keydownHandler = (event) => {
        if (event.ctrlKey && event.key === 'x') {
              event.preventDefault();
              this.systemNotice = false
          }
      };
      window.removeEventListener('keydown', keydownHandler);
      window.addEventListener('keydown', keydownHandler);
      
    }
  },
  mounted(){
    this.handleKeys()
  },
  // mounted() {
  //   try {
  //     axios.get(`http://10.169.142.41:4567/test/getMaintenanceStatus/${process.env.VUE_APP_DEPLOYMENT}`).then(res => {
  //         if(res.data.length > 0){
  //           this.systemNotice = res.data[0].Status != 0 ? true : false
  //         }
  //     })
  //   } catch (error) {
  //     if(error){
  //       console.log(error);
  //     }
  //   }
  // },
  computed: {
    imageSource() {
      return require(`../public/img/flags/${this.image}.png`);
    },
    userInfo() {
      return this.$root.decryptedUser;
    }
  },
  watch: {
    userInfo: {
      handler(newUserInfo) {
        if (newUserInfo !== null) {
          this.isDefault();
          this.loadVersion();
        }
      },
      immediate: true, // Call the handler immediately when the component is created
    },
    'update.newPassword': function() {
      this.$refs.password.validate();
    },
    'update.confirmPassword': function() {
      this.$refs.password.validate();
    }
  },
};
</script>
<style lang="css" scoped>


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

