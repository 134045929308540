<template>
    <img style="width: 300px; position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" width="300vw" height="300vw" :src="imageSource" />
</template>

<script>
export default {
    data(){
        return {
            imageList: [
                {imageLink: '/', label: 'test.png'},
                {imageLink: '/tsuikahenkou', label: 'THData.png'},
                {imageLink: '/cadrequest', label: 'CadRequest.png'},
                {imageLink: '/daysdata', label: 'Days.png'},
                {imageLink: '/overalldata', label: 'Overall.png'},
                {imageLink: '/salesoffice', label: 'SalesOffice.png'},
            ],
            currentRoute: this.$route.path
        }
    },
    computed: {
        imageSource() {
            const image = this.imageList.find(item => item.imageLink == this.currentRoute)
            return require(`@/assets/Logo/${image.label}`)
        },
    },
}
</script>