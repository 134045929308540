export default class HalfYear {
    constructor(){
        this.HlfYr=[]
        this.HYear=''
        this.Body=[]
        this.totalQrtly={
            TotalKakou:0,
            TotalTH:0,
            TotalAvg:0,
            TotalSalesAvg:0,
            TotalNationAvg:0
          }
        this.HYear=''
        this.newArr = []
    }
    getHalfYear(yearHear,monthHeader,plannersBody,SalesBody,nationBody,tempArr){
        return new Promise(resolve=>{
            this.HlfYr=[]
            this.Body=[]
            this.HYear=''
            this.HYear=`${yearHear[0].year  +'-'+yearHear[1].year }`
            this.HlfYr=[
                `${monthHeader[0]+"月"+'-'+monthHeader[6]+"月"}`,
                `${monthHeader[7]+"月"+'-'+monthHeader[12]+"月"}`,
            ]
            this.newArr = []
            plannersBody.forEach((r,i )=> {
                if(i==0 || i==1){
                    this.Body.push([
                        r[0],
                        r[1]+r[2]+r[3]+r[4]+r[5]+r[6]+r[7],
                        r[8]+r[9]+r[10]+r[11]+r[12]+r[13]
                    ])
                }
            })
            let x1= this.Body[1][1]  / this.Body[0][1]
            let x2= this.Body[1][2]  / this.Body[0][2]

                    this.Body.push([
                        'Average No of TH',
                        x1 ==Infinity || isNaN(x1) || x1==isNaN ||  x1== isFinite ?  0 : parseFloat(x1).toFixed(1),
                    x2 ==Infinity || isNaN(x2) || x2==isNaN ||  x2== isFinite ?  0 : parseFloat(x2).toFixed(1)
                    ])    
               
                    // let a =parseFloat((((this.Body[2][1]+this.Body[2][2])/4)*0.8).toFixed(1))
                    // this.Body.push(['Target',a,a])
               
          
            let testArr=[]
            yearHear.forEach(r=>{
                r.months.forEach(rr=>{
               let  index2=SalesBody.findIndex(y=> y.year == r.year && y.month == rr)
                    if(index2 ==-1){
                        testArr.push({
                            avg:0.0,
                            kakou:0,
                            th:0,
                            month:rr,
                            year:r.year
                        })
                    }else{
                        testArr.push(SalesBody[index2])
                    }
                })
            })

            let arr=[
                {
                    // kakou: testArr[0].kakou + testArr[1].kakou + testArr[2].kakou + testArr[3].kakou + testArr[4].kakou + testArr[5].kakou + testArr[6].kakou,
                    // th: testArr[0].th + testArr[1].th + testArr[2].th + testArr[3].th +  testArr[4].th + testArr[5].th + testArr[6].th
                    kakou: (testArr[0].kakou || 0) + (testArr[1].kakou || 0) + (testArr[2].kakou || 0) + (testArr[3].kakou || 0) + (testArr[4].kakou || 0) + (testArr[5].kakou || 0) + (testArr[6].kakou || 0),
                    th: (testArr[0].th || 0) + (testArr[1].th || 0) + (testArr[2].th || 0) + (testArr[3].th || 0) + (testArr[4].th || 0) + (testArr[5].th || 0) + (testArr[6].th || 0)
                },
            
                {
                    // kakou: testArr[7].kakou + testArr[8].kakou + testArr[9].kakou +testArr[10].kakou + testArr[11].kakou + testArr[12]? testArr[12].kakou: 0, 
                    // th: testArr[7].th + testArr[8].th + testArr[9].th +testArr[10].th + testArr[11].th + testArr[12]? testArr[12].th : 0
                    kakou: (testArr[7].kakou || 0) + (testArr[8].kakou || 0) + (testArr[9].kakou || 0) + (testArr[10].kakou || 0) + (testArr[11].kakou || 0) + (testArr[12].kakou || 0),
                    th: (testArr[7].th || 0) + (testArr[8].th || 0) + (testArr[9].th || 0) + (testArr[10].th || 0) + (testArr[11].th || 0) + (testArr[12].th || 0)
                }, 
              
            ]
            let tempSalesArr = ['Average SalesOffice'] 
            arr.forEach(x=>{
                x.avg = x.th/x.kakou
                x.avg =  x.avg ==Infinity || isNaN(x.avg) || x.avg==isNaN ||  x.avg== isFinite ? 0.0 :  (x.avg).toFixed(1)
                tempSalesArr.push(x.avg)
            })
            this.Body.push(tempSalesArr)

            let arr2=[
                {
                    // kakou: nationBody[0].kakou + nationBody[1].kakou + nationBody[2].kakou + nationBody[3].kakou + nationBody[4].kakou + nationBody[5].kakou + nationBody[6].kakou,
                    // th: nationBody[0].th + nationBody[1].th + nationBody[2].th + nationBody[3].th +  nationBody[4].th + nationBody[5].th + nationBody[6].th
                    kakou: (nationBody[0].kakou || 0) + (nationBody[1].kakou || 0) + (nationBody[2].kakou || 0) + (nationBody[3].kakou || 0) + (nationBody[4].kakou || 0) + (nationBody[5].kakou || 0) + (nationBody[6].kakou || 0),
                    th: (nationBody[0].th || 0) + (nationBody[1].th || 0) + (nationBody[2].th || 0) + (nationBody[3].th || 0) + (nationBody[4].th || 0) + (nationBody[5].th || 0) + (nationBody[6].th || 0)
                },
            
                {
                    // kakou: nationBody[7].kakou + nationBody[8].kakou + nationBody[9].kakou +nationBody[10].kakou + nationBody[11].kakou + nationBody[12]? nationBody[12].kakou: 0, 
                    // th: nationBody[7].th + nationBody[8].th + nationBody[9].th +nationBody[10].th + nationBody[11].th + nationBody[12]? nationBody[12].th : 0
                    kakou: (nationBody[7].kakou || 0) + (nationBody[8].kakou || 0) + (nationBody[9].kakou || 0) + (nationBody[10].kakou || 0) + (nationBody[11].kakou || 0) + (nationBody[12].kakou || 0),
                    th: (nationBody[7].th || 0) + (nationBody[8].th || 0) + (nationBody[9].th || 0) + (nationBody[10].th || 0) + (nationBody[11].th || 0) + (nationBody[12].th || 0)
                }, 
              
            ]
            let tempNationArr = ['Average Nationwide'] 
            arr2.forEach(x=>{
                x.avg = x.th/x.kakou
                x.avg =  x.avg ==Infinity || isNaN(x.avg) || x.avg==isNaN ||  x.avg== isFinite ? 0.0 :  (x.avg).toFixed(1)
                tempNationArr.push(x.avg)
            })
           this.Body.push(tempNationArr)
//=================================================================================================

           this.totalQrtly={
            TotalKakou:0,
            TotalTH:0,
            TotalAvg:0,
            TotalSalesAvg:0,
            TotalNationAvg:0
          }

          this.Body[0].forEach((total,index)=>{
            if(index!=0){
            this.totalQrtly.TotalKakou += ( total || 0)
            }
        })
        this.Body[1].forEach((total,index)=>{
            if(index!=0){
              this.totalQrtly.TotalTH += ( total || 0)
            }
        })
        this.totalQrtly.TotalAvg = (this.totalQrtly.TotalTH / this.totalQrtly.TotalKakou).toFixed(2)
        // this.totalQrtly.totalTarget = this.Body[3][1].toFixed(1)

        // this.Body[3].forEach((total,index)=>{
        //     if(index!=0 && index!=13){
        //         this.totalQrtly.TotalSalesAvg += total ? parseFloat(total) :0
        //     }
        // })
        // this.totalQrtly.TotalSalesAvg =(this.totalQrtly.TotalSalesAvg/4).toFixed(2)
        let totalSalesKakou = 0
        let totalSalesTH = 0
        arr.forEach(total => {
            totalSalesKakou += (total.kakou || 0),
            totalSalesTH += (total.th || 0)
        })
        this.totalQrtly.TotalSalesAvg = (totalSalesTH / totalSalesKakou).toFixed(2)

        // this.Body[4].forEach((total,index)=>{
        //     if(index!=0 && index!=13){
        //         this.totalQrtly.TotalNationAvg += total ? parseFloat(total) :0
        //     }
        // })

        // this.totalQrtly.TotalNationAvg =(this.totalQrtly.TotalNationAvg/4).toFixed(2)
        let totalNationKakou = 0
        let totalNationTH = 0
        arr2.forEach(total => {
            totalNationKakou += (total.kakou || 0),
            totalNationTH += (total.th || 0)
        })
        this.totalQrtly.TotalNationAvg =(totalNationTH / totalNationKakou).toFixed(2)
//=================================================================================================
        this.newArr = []
        this.newArr = tempArr.filter((rec)=>{
            rec.newCount2=[[],[]]
                rec.count.filter((r,i)=>{
                        if(i >= 0 && i <= 6){
                            if(typeof(r) == 'object'){
                                rec.newCount2[0].push({
                                    targetKomoku:r.targetKomoku,
                                    count :r.count,
                                    changeAfter:[...r.changeAfter]
                                })
                            }
                        }
                        
                        else if(i >= 7 && i <=12){
                            if(typeof(r) == 'object'){
                                rec.newCount2[1].push({
                                    targetKomoku:r.targetKomoku,
                                    count :r.count,
                                    changeAfter:[...r.changeAfter]
                                })
                            }
                        }
                    
                })
            return rec
        })
        this.newArr.forEach(rec=>{
            rec.test2 =[[],[]]
            rec.newCount2.filter((ds,i)=>{
                if(ds.length > 0){
                    ds = ds.reduce((total,item)=>{
                        let index = total.findIndex(y=> y.targetKomoku == item.targetKomoku)
                        if(index > -1 ){
                            total[index].count += item.count
                            total[index].changeAfter = [...total[index].changeAfter,...item.changeAfter]
                            return total
                            }
                        else{
                            total.push(
                                {
                                    targetKomoku:item.targetKomoku,
                                    count :item.count,
                                    changeAfter:item.changeAfter
                                }
                            )
                            return total
                        }
                    },[])
                }    
                rec.test2[i]=ds
            })
        })
            resolve({Year:this.HYear,header1:this.HlfYr,body1:this.Body,totalQrtly1:this.totalQrtly,newArr1:this.newArr})
        })
    }
}