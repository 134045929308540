import moment from 'moment'
import axios from 'axios'
import Swal from 'sweetalert2'
import store from '../../../store'
import router from '../../../router'

export default class QuarterlyClass{
    constructor(){
        this.Quarterly=[]
        this.Quarterly2=[]

    }   
    getQuarterly(plannerCodeRange,SO,aws,apiKey,planners,Select){
        return new Promise(resolve=>{
                if(Select =='Quarterly'){
                this.Quarterly= [
                        {
                            fromDate:plannerCodeRange.fromDate,
                            toDate:moment(plannerCodeRange.fromDate).add(3,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(4,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:moment(plannerCodeRange.fromDate).add(6,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(7,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:moment(plannerCodeRange.fromDate).add(9,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(10,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:plannerCodeRange.toDate,
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                    ]  
                }else if(Select =='HalfYear'){
                    this.Quarterly= [
                        {
                            fromDate:plannerCodeRange.fromDate,
                            toDate:moment(plannerCodeRange.fromDate).add(6,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(7,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:plannerCodeRange.toDate,
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                    ]  
                }
            const fn =  async () => {
            let Promises=[]
                for(let i=0; i <= this.Quarterly.length-1 ;i++){
                    let x =  await GetTH(this.Quarterly[i],aws,apiKey,planners,i);
                    Promises.push(x)
                }
                Promise.all(Promises).then(sales=>{
                    resolve({data:sales,header:this.Quarterly})
                })
            }
            fn()
        })
    }
    getNationQrtly(plannerCodeRange,SO,aws,apiKey,planners,Select ){
        return new Promise(resolve=>{
                this.Quarterly2= [
                        {
                            fromDate:plannerCodeRange.fromDate,
                            toDate:moment(plannerCodeRange.fromDate).add(3,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(4,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:moment(plannerCodeRange.fromDate).add(6,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(7,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:moment(plannerCodeRange.fromDate).add(9,'months').endOf('month').format("YYYY-MM-DD"),
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                        {
                            fromDate:moment(plannerCodeRange.fromDate).add(10,'months').startOf('month').format("YYYY-MM-DD"),
                            toDate:plannerCodeRange.toDate,
                            SalesOfficeCode:SO.SalesOfficeCode,
                            SalesOfficeName:SO.SalesOfficeName,
                            planners:planners
                        },
                    ]  
                
            const fn =  async () => {
            let Promises=[]
                for(let i=0; i <= this.Quarterly2.length-1 ;i++){
                        let x =  await getTHNation(this.Quarterly2[i],aws,apiKey,planners,i,Select);
                        Promises.push(x)
                }
                Promise.all(Promises).then(sales=>{
                    if(Select == 'HalfYear'){
                        forHalfyear(sales).then(output=>{
                            // console.log(output,'output')
                            resolve({data:output})
                        })
                    }else{
                        resolve({data:sales})
                    }
                })
            }
            fn()
        })
    }
 
}
async function GetTH(data,aws,apiKey,planners,i){
    try {
    let res = await axios.post(`${aws}thRanking2`,data,apiKey)
    console.log(res.data, 'thRanking2');
            let arr = res.data.reduce((total,item)=>{
                let index = planners.findIndex(y => y.EmployeeCode == item.EmployeeCode)
                if (index > -1 ){
                    let index2 = total.findIndex(x => x.EmployeeCode == item.EmployeeCode)
                    if(index2 > -1 ){
                            total[index2].TH += item.TH
                            total[index2].KakouIrai += item.SentProcessRequestDate ? 1 : 0
                            return total
                    }else{
                        total.push({
                            Quarter:i+1,
                            EmployeeCode:item.EmployeeCode,
                            EmployeeName:item.employee_name,
                            TH:item.TH,
                            KakouIrai: item.SentProcessRequestDate ? 1 : 0,
                            Cad:0,
                            CadExpress:0,
                            CadContract:0,
                            CadPhase1:0,
                            CadPdr:0,
                            CadFinal:0,
                            Denki:0,
                            DenkiExpress:0,
                            ExternalPers:0,
                            HP:0,
                            SI_Waku:0,
                            SI_Jiku:0,
                            ALVS:0,
                            DecidedLandDays:0,
                            StopCheck:0,
                            FinalPlan:0,
                            Joutou:0,

                        })
                        return total;
                    }
                }else{
                    
                    return total;
                }
            },[]).sort((a,b)=>{
                return a.EmployeeCode - b.EmployeeCode
            })
            console.log(arr, 'arr');
           
            if(arr.length > 0){
               let x = getCad(data,aws,apiKey,arr)
               return x;
            }
    } catch (err) {
        if(err.response.status == 403 || err.response.status == 401){
            logout()
        }
    }
}
async function getCad(data,aws,apiKey,val){
    try {
    let res = await  axios.post(`${aws}getAllCad`,data,apiKey)
    console.log(res.data, 'cad');
    //  let res = await  axios.post(`http://10.11.1.59:4027/test/getAllCad`,data)
            let Holder=[]
            res.data.filter((x)=>{
                val.forEach(y => {
                    if(x.EmployeeCode == y.EmployeeCode){
                        Holder.push(x)  
                    }
                });
            })
            Holder.filter((item)=>{
                let index = val.findIndex(x => x.EmployeeCode ==item.EmployeeCode)
                if(index > -1 ){
                
                    val[index].Cad += item.cad ?  item.cad :0
                    val[index].CadExpress += item.CadExpress ?  item.CadExpress :0
                    val[index].CadContract += item.CadContract ?  item.CadContract :0
                    val[index].CadPhase1 += item.CadPhase1 ?  item.CadPhase1 :0
                    val[index].CadPdr += item.CadPdr > 0 ? item.CadPdr : item.CadDenkiPlan ?  item.CadDenkiPlan :0
                    val[index].CadFinal += item.CadFinalPlan ?  item.CadFinalPlan :0
                    val[index].Denki += item.denki ? item.denki : 0
                    val[index].DenkiExpress += item.denkiExpress ? item.denkiExpress : 0
                    val[index].ExternalPers += item.ExternalPers ? item.ExternalPers : 0
                    val[index].HP += item.House_presentation ? item.House_presentation :0
                    val[index].SI_Waku += item.SI_WAKU_DUCT ? item.SI_WAKU_DUCT : 0
                    val[index].SI_Jiku += item.SI_JIKU ? item.SI_JIKU  : 0
                    val[index].ALVS += item.SI_JIKU ? item.SI_JIKU  : 0
                    val[index].DecidedLandDays += item.decidedlandDays ? item.decidedlandDays : 0
                    val[index].StopCheck += item.stopcheck ? item.stopcheck : 0,
                    val[index].FinalPlan += item.finalPlan ? item.finalPlan : 0,
                    val[index].Joutou += item.JoutouDays ? item.JoutouDays : 0
                }
            })

            for(let i =0; i <= val.length-1; i++){
                val[i].THavg = tofixed2(val[i].TH / val[i].KakouIrai)
                val[i].CadAvg = tofixed2(val[i].Cad / val[i].KakouIrai)
                val[i].CadExpressAvg = tofixed2(val[i].CadExpress / val[i].KakouIrai)
                val[i].CadContractAvg = tofixed2(val[i].CadContract / val[i].KakouIrai)
                val[i].CadPhase1Avg = tofixed2(val[i].CadPhase1 / val[i].KakouIrai)
                val[i].CadPdrAvg = tofixed2(val[i].CadPdr / val[i].KakouIrai)
                val[i].CadFinalAvg = tofixed2(val[i].CadFinal / val[i].KakouIrai)
                val[i].DenkiAvg = tofixed2(val[i].Denki / val[i].KakouIrai)
                val[i].DenkiExpressAvg = tofixed2(val[i].DenkiExpress / val[i].KakouIrai)
                val[i].ExternalPersAvg =tofixed2(val[i].ExternalPers / val[i].KakouIrai)
                val[i].HPAvg = tofixed2(val[i].HP / val[i].KakouIrai)
                val[i].SI_WakuAvg = tofixed2(val[i].SI_Waku / val[i].KakouIrai)
                val[i].SI_JikuAvg = tofixed2(val[i].SI_Jiku / val[i].KakouIrai)
                val[i].ALVSAvg = tofixed2(val[i].ALVS / val[i].KakouIrai)
                val[i].DecidedLandDaysAvg = tofixed2(val[i].DecidedLandDays / val[i].KakouIrai)
                val[i].StopCheckAvg = tofixed2(val[i].StopCheck / val[i].KakouIrai)
                val[i].FinalPlanAvg = tofixed2(val[i].FinalPlan / val[i].KakouIrai)
                val[i].JoutouAvg = tofixed2(val[i].Joutou / val[i].KakouIrai)

            }

        return(val)
    } catch (err) {
        if(err.response.status == 403 || err.response.status == 401){
            logout()
        }
    }
}
function forHalfyear(data){
    return new Promise(resolve=>{
        let arr = [[...data[0],...data[1]],[...data[2],...data[3]]]
        let resolveArr=[]
                arr.forEach((val,i)=>{
                    let arr2=val.reduce((total,item)=>{
                        let index = total.findIndex(x=> x.EmployeeCode == item.EmployeeCode )
                        if(index > -1){
                            total[index].TH += item.TH
                            total[index].KakouIrai += item.KakouIrai
                            total[index].Cad += item.Cad
                            total[index].CadExpress += item.CadExpress
                            total[index].CadContract += item.CadContract
                            total[index].CadPhase1 += item.CadPhase1
                            total[index].CadPdr += item.CadPdr
                            total[index].CadFinal += item.CadFinal
                            total[index].Denki += item.Denki
                            total[index].DenkiExpress += item.DenkiExpress
                            total[index].ExternalPers += item.ExternalPers
                            total[index].HP += item.HP
                            total[index].SI_Waku += item.SI_Waku
                            total[index].SI_Jiku += item.SI_Jiku
                            total[index].ALVS += item.ALVS
                            total[index].DecidedLandDays += item.DecidedLandDays
                            total[index].StopCheck += item.StopCheck
                            total[index].FinalPlan += item.FinalPlan
                            total[index].Joutou += item.Joutou
                            return total;

                        }else{
                            total.push({
                                Quarter:i+1,
                                EmployeeCode:item.EmployeeCode,
                                EmployeeName:item.EmployeeName,
                                TH:item.TH,
                                KakouIrai: item.KakouIrai,
                                Cad:item.Cad,
                                CadExpress:item.CadExpress,
                                CadContract:item.CadContract,
                                CadPhase1:item.CadPhase1,
                                CadPdr:item.CadPdr,
                                CadFinal:item.CadFinal,
                                Denki:item.Denki,
                                DenkiExpress:item.DenkiExpress,
                                ExternalPers:item.ExternalPers,
                                HP:item.HP,
                                SI_Waku:item.SI_Waku,
                                SI_Jiku:item.SI_Jiku,
                                ALVS:item.ALVS,
                                DecidedLandDays:item.DecidedLandDays,
                                StopCheck:item.StopCheck,
                                FinalPlan:item.FinalPlan,
                                Joutou:item.Joutou,
                            })
                            return total;
                        }
                    },[]) 

                    arr2[i].THavg = tofixed2(arr2[i].TH / arr2[i].KakouIrai)
                    arr2[i].CadAvg = tofixed2(arr2[i].Cad / arr2[i].KakouIrai)
                    arr2[i].CadExpressAvg = tofixed2(arr2[i].CadExpress / arr2[i].KakouIrai)
                    arr2[i].CadContractAvg = tofixed2(arr2[i].CadContract / arr2[i].KakouIrai)
                    arr2[i].CadPhase1Avg = tofixed2(arr2[i].CadPhase1 / arr2[i].KakouIrai)
                    arr2[i].CadPdrAvg = tofixed2(arr2[i].CadPdr / arr2[i].KakouIrai)
                    arr2[i].CadFinalAvg = tofixed2(arr2[i].CadFinal / arr2[i].KakouIrai)
                    arr2[i].DenkiAvg = tofixed2(arr2[i].Denki / arr2[i].KakouIrai)
                    arr2[i].DenkiExpressAvg = tofixed2(arr2[i].DenkiExpress / arr2[i].KakouIrai)
                    arr2[i].ExternalPersAvg =tofixed2(arr2[i].ExternalPers / arr2[i].KakouIrai)
                    arr2[i].HPAvg = tofixed2(arr2[i].HP / arr2[i].KakouIrai)
                    arr2[i].SI_WakuAvg = tofixed2(arr2[i].SI_Waku / arr2[i].KakouIrai)
                    arr2[i].SI_JikuAvg = tofixed2(arr2[i].SI_Jiku / arr2[i].KakouIrai)
                    arr2[i].ALVSAvg = tofixed2(arr2[i].ALVS / arr2[i].KakouIrai)
                    arr2[i].DecidedLandDaysAvg = tofixed2(arr2[i].DecidedLandDays / arr2[i].KakouIrai)
                    arr2[i].StopCheckAvg = tofixed2(arr2[i].StopCheck / arr2[i].KakouIrai)
                    arr2[i].FinalPlanAvg = tofixed2(arr2[i].FinalPlan / arr2[i].KakouIrai)
                    arr2[i].JoutouAvg = tofixed2(arr2[i].Joutou / arr2[i].KakouIrai)
                    resolveArr.push(arr2)
                })
        resolve(resolveArr)
    })
}
async function getTHNation(data,aws,apiKey,planners,i,Select){
    try {
    let res = await axios.post(`${aws}thRanking`,data,apiKey)
        let arr = res.data.reduce((total,item)=>{
                let index2 = total.findIndex(x => x.EmployeeCode == item.EmployeeCode)
                if(index2 > -1 ){
                        total[index2].TH += item.TH
                        total[index2].KakouIrai += item.SentProcessRequestDate ? 1 : 0
                        return total
                }else{
                    total.push({
                        Quarter:i+1,
                        EmployeeCode:item.EmployeeCode,
                        EmployeeName:item.employee_name,
                        TH:item.TH,
                        KakouIrai: item.SentProcessRequestDate ? 1 : 0,
                        Cad:0,
                        CadExpress:0,
                        CadContract:0,
                        CadPhase1:0,
                        CadPdr:0,
                        CadFinal:0,
                        Denki:0,
                        DenkiExpress:0,
                        ExternalPers:0,
                        HP:0,
                        SI_Waku:0,
                        SI_Jiku:0,
                        ALVS:0,
                        DecidedLandDays:0,
                        StopCheck:0,
                        FinalPlan:0,
                        Joutou:0,
                    })
                    return total;
                }
    },[]).sort((a,b)=>{
        return a.EmployeeCode - b.EmployeeCode
    })
    let obj = {
        planners : res.data,
        fromDate: data.fromDate,
        toDate:data.toDate,
    }
        if(arr.length > 0){
        let x = getCad2(obj,aws,apiKey,arr,Select)
        return x;
    }
            
    } catch (err) {
        if(err.response.status == 403 || err.response.status == 401){
            logout()
        }   
    }
}
async function getCad2(data,aws,apiKey,val,Select){
    try {
        let Holder=[]
        let res = await  axios.post(`${aws}getAllCad`,data,apiKey)
        // let res = await  axios.post(`http://10.11.1.59:4027/test/getAllCad`,data)
            res.data.filter((x)=>{
                val.forEach(y => {
                    if(x.EmployeeCode == y.EmployeeCode){
                        Holder.push(x)  
                    }
                });
            })
            Holder.filter((item)=>{
                let index = val.findIndex(x => x.EmployeeCode ==item.EmployeeCode)
                if(index > -1 ){
            
                    val[index].Cad += item.cad ?  item.cad :0
                    val[index].CadExpress += item.CadExpress ?  item.CadExpress :0
                    val[index].CadContract += item.CadContract ?  item.CadContract :0
                    val[index].CadPhase1 += item.CadPhase1 ?  item.CadPhase1 :0
                    val[index].CadPdr += item.CadPdr ?  item.CadPdr :0
                    val[index].CadFinal += item.CadFinalPlan ?  item.CadFinalPlan :0
                    val[index].Denki += item.denki ? item.denki : 0
                    val[index].DenkiExpress += item.denkiExpress ? item.denkiExpress : 0
                    val[index].ExternalPers += item.ExternalPers ? item.ExternalPers : 0
                    val[index].HP += item.House_presentation ? item.House_presentation :0
                    val[index].SI_Waku += item.SI_WAKU_DUCT ? item.SI_WAKU_DUCT : 0
                    val[index].SI_Jiku += item.SI_JIKU ? item.SI_JIKU  : 0
                    val[index].ALVS += item.SI_JIKU ? item.SI_JIKU  : 0
                    val[index].DecidedLandDays += item.decidedlandDays ? item.decidedlandDays : 0
                    val[index].StopCheck += item.stopcheck ? item.stopcheck : 0,
                    val[index].FinalPlan += item.finalPlan ? item.finalPlan : 0,
                    val[index].Joutou += item.JoutouDays ? item.JoutouDays : 0
                }
            })
        if(Select=='Quarterly'){
            for(let i =0; i <= val.length-1; i++){
                val[i].THavg = tofixed2(val[i].TH / val[i].KakouIrai)
                val[i].CadAvg = tofixed2(val[i].Cad / val[i].KakouIrai)
                val[i].CadExpressAvg = tofixed2(val[i].CadExpress / val[i].KakouIrai)
                val[i].CadContractAvg = tofixed2(val[i].CadContract / val[i].KakouIrai)
                val[i].CadPhase1Avg = tofixed2(val[i].CadPhase1 / val[i].KakouIrai)
                val[i].CadPdrAvg = tofixed2(val[i].CadPdr / val[i].KakouIrai)
                val[i].CadFinalAvg = tofixed2(val[i].CadFinal / val[i].KakouIrai)
                val[i].DenkiAvg = tofixed2(val[i].Denki / val[i].KakouIrai)
                val[i].DenkiExpressAvg = tofixed2(val[i].DenkiExpress / val[i].KakouIrai)
                val[i].ExternalPersAvg =tofixed2(val[i].ExternalPers / val[i].KakouIrai)
                val[i].HPAvg = tofixed2(val[i].HP / val[i].KakouIrai)
                val[i].SI_WakuAvg = tofixed2(val[i].SI_Waku / val[i].KakouIrai)
                val[i].SI_JikuAvg = tofixed2(val[i].SI_Jiku / val[i].KakouIrai)
                val[i].ALVSAvg = tofixed2(val[i].ALVS / val[i].KakouIrai)
                val[i].DecidedLandDaysAvg = tofixed2(val[i].DecidedLandDays / val[i].KakouIrai)
                val[i].StopCheckAvg = tofixed2(val[i].StopCheck / val[i].KakouIrai)
                val[i].FinalPlanAvg = tofixed2(val[i].FinalPlan / val[i].KakouIrai)
                val[i].JoutouAvg = tofixed2(val[i].Joutou / val[i].KakouIrai)
            }
            return(val)
        }else{
            return(val)
        }
    } catch (err) {
        if(err.response.status == 403 || err.response.status == 401){
            logout()
        }
    }
}
function tofixed2(num){
    return (Math.round((num ) * 10) / 10).toFixed(1)
 }
function logout(){
    Swal.fire({
        icon: "info",
        title: `Token Expired System will forced logout`,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          store.commit("STORE_USERINFO", null);
          store.commit("STORE_SALESOFFICE", null)
          store.commit("STORE_CHART_URL", null);
          router.push("/login");
        }
      });
}