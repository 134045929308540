<template>
  <v-overlay :value="loading" color="#000000">
    <div class="d-flex flex-column align-center">
      <v-progress-circular
        :width="10"
        color="primary"
        indeterminate
        :size="70"
      >
      </v-progress-circular>
      <div class="mt-2">{{'ローディング中....'}}</div>
    </div>
  </v-overlay>
</template>

<script>
  export default {
    props:{
      loading: Boolean
    }
  }
</script>

<style lang="scss" scoped>
.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}
</style>