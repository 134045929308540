import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import router from '../../../router'
import store from '../../../store'
export default class VersionLogs{
  constructor() {
    this.detailsOfUpdate = [];
    this.latestVersion = '';
  }
  getLogs(aws,apiKey,deployment) {
    this.aws = aws
    this.apiKey = apiKey
    // deployment = 'actual'
    axios.get(deployment == 'test' ? `${this.aws}versionlogstest` : `${this.aws}versionlogs`, this.apiKey)
      .then(res => {
        this.detailsOfUpdate = res.data.filter(r => {
          r.ContentofRequest = r.ContentofRequest.replace(/(?:\r\n|\r|\n)/g, '<br>');
          r.DateRequested = moment(r.DateRequested).format('YYYY/MM/DD');
          return r;
        }).sort((a, b) => {
          return b.DateRequested - a.DateRequested || b.id - a.id;
        });
        if(this.detailsOfUpdate){
          this.latestVersion = this.detailsOfUpdate[0].Version
        }
      }).catch(err=>{
        if(err.response.status == 403 || err.response.status == 401){
          Swal.fire({
            icon: "info",
            title: `Token Expired System will forced logout`,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit("STORE_USERINFO", null);
              store.commit("STORE_SALESOFFICE", null)
              store.commit("STORE_CHART_URL", null);
              router.push("/login");
            }
          });
        }
      });
  }
}
